<template>
  <div class="user-block">
    <inline-svg class="user-block__icon g-hidden-mobile" src="u-profile" />
    <div class="user-block__menu">
      <div class="user-block__menu-inner">
        <a :href="hrefWithRegion(lkUrl)" class="user-block__item">
          <inline-svg class="user-block__item-icon g-visible-mobile" src="u-profile" />
          Личный кабинет
        </a>
        <a :href="hrefWithRegion(logoutUrl)" class="user-block__item user-block__item--log-out">
          Выйти
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lkUrl: {
      type: String,
      require: true
    },
    logoutUrl: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.user-block';

#{$b} {
  position: relative;

  // .user-block__icon
  &__icon {
    width: 15px;
    height: 20px;
    color: $white-true;
    cursor: pointer;

    svg {
      & > * {
        fill: transparent !important;
        stroke: currentColor;
      }
    }
  }

  // .user-block__menu
  &__menu {
    font-family: $font-family-inter;
    font-size: 16px;
    line-height: 1.3;
    color: $black-true;
    transition: $transtion-default;
    overflow: hidden;

    @include mobile-min {
      position: absolute;
      top: calc(100% - 10px);
      right: -10px;
      transform: translateY(10px);
      opacity: 0;
      pointer-events: none;
      touch-action: none;
      padding-top: 20px;
      z-index: 10;
    }

    #{$b}:hover & {
      @include mobile-min {
        transform: translateY(0);
        opacity: 1;
        pointer-events: unset;
        touch-action: unset;
      }
    }

    @include mobile {
      color: $white-true;
    }
  }

  // .user-block__menu-inner
  &__menu-inner {
    @include mobile-min {
      border-radius: 16px;
      background-color: $white-true;
      padding: 16px;
    }
  }

  // .user-block__item
  &__item {
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    color: inherit;
    transition: $transtion-default;

    // .user-block__item--log-out
    &--log-out {
      text-decoration: underline;
      text-decoration-color: rgba($black-true, 0.3);
      text-underline-offset: 3px;

      @include mobile {
        margin-top: 24px;
        text-decoration-color: $white-true;
      }
    }

    &:hover {
      @include mobile-min {
        color: $color-base;
      }
    }

    &:not(:last-child) {
      @include mobile-min {
        margin-bottom: 15px;
      }
    }

    @include mobile {
      font-family: $font-family-fugue;
      font-size: 22px;
      line-height: 1.4;
      text-transform: uppercase;
    }

    &-icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;

      svg {
        & > * {
          fill: transparent !important;
          stroke: currentColor;
        }
      }
    }
  }
}
</style>
