<template>
  <a :href="hrefWithRegion(data.url)" class="u-event-small-card">
    <time class="u-event-small-card__date tt-uppercase">{{data.period}}</time>
    <h3 class="u-event-small-card__title tt-uppercase" v-html="data.title"></h3>
    <p
      v-if="data.organizers && data.organizers.length"
      class="u-event-small-card__text">
      <span class="u-event-small-card__text-static">
        При поддержке:
      </span>
      <span
        v-for="(item,i) in data.organizers"
        :key="`u-event-small-card__text-item-${i}`"
        class="u-event-small-card__text-item" v-html="item.name"></span>
    </p>
    <div class="u-event-small-card__actions">
      <CustomButton
        class="u-event-small-card__btn"
      >
        Узнать больше
      </CustomButton>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

.u-event-small-card {
  display: flex;
  flex-direction: column;
  font-family: $font-family-inter;
  color: $white-true;
  padding: 24px;
  background-color: $color-base;
  border-radius: 16px;
  text-decoration: none;
  transition: $transtion-default;

  &:hover {
    @include mobile-min {
      transform: scale(1.01);
    }
  }

  @include mobile-min {
    min-height: 400px;
  }

  // .u-event-small-card__date
  &__date {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    margin: 0 0 24px 0;
  }

  // .u-event-small-card__title
  &__title {
    font-family: $font-family-fugue;
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    word-break: break-word;
    margin: auto 0 0 0;
  }

  // .u-event-small-card__text
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;

    margin: 16px 0 0 0;

    @include mobile-min {
      display: none;
    }
  }

  // .u-event-small-card__text-item
  &__text-item {
    &:not(:last-child) {
      &::after {
        content: ", ";
      }
    }
  }

  // .u-event-small-card__actions
  &__actions {
    margin-top: 24px;
  }
}
</style>
