<template>
  <div class="u-events-filter">
    <div class="u-events-filter__top">
      <div class="u-events-filter__overflow" v-dragscroll.x:nochilddrag>
        <ul class="u-events-filter__list">
          <li v-for="(item, i) in filters" :key="i">
            <div
              class="u-events-filter__item"
              :class="{ 'is-active': item.isActive }"
              @click.prevent="toggleFilter(item, i)"
            >
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="bottomFiltersShowed"
      class="u-events-filter__bottom"
    >
      <ul class="u-events-filter__dropdowns">
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.region"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.code"
                :options="regionOptions"
                @input="(v) => onChangeSelect('region', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.organizer"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="partnersOptions"
                @input="(v) => onChangeSelect('organizer', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item" >
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.type"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="eventTypeOptions"
                @input="(v) => onChangeSelect('type', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item" >
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.initiative"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="initiativesOptions"
                @input="(v) => onChangeSelect('initiative', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.audience"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="audienceOptions"
                @input="(v) => onChangeSelect('audience', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
        <li class="u-events-filter__dropdown-item">
          <div class="u-events-filter__dropdown">
            <div class="u-select">
              <v-select
                :value="selectedOptions.year"
                :clearable="false"
                :searchable="true"
                label="name"
                :components="{ OpenIndicator }"
                :reduce="option => option.id"
                :options="yearOptions"
                @input="(v) => onChangeSelect('year', v)"
              >
                <template #no-options>
                  Не найдено
                </template>
              </v-select>
            </div>
          </div>
        </li>
      </ul>
      <ul class="u-events-filter__month-list">
        <li v-show="showMonth(i)" v-for="(item, i) in monthFilters" :key="i">
          <div
            class="u-events-filter__month-item text-md tt-uppercase"
            :class="{ 'is-active': item.isActive }"
            @click.prevent="toggleMonthFilter(item, i)"
          >
            <span>{{ item.name }}</span>
            <InlineSvg v-if="item.isActive && i > 0" src="close" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import isMatch from 'lodash-es/isMatch'
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  name: 'EventsFilter',
  components: { vSelect },
  props: {
    regionOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Все регионы' },
        { id: '1', name: 'Не все регионы' }
      ]
    },
    partnersOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Все организаторы' },
        { id: '1', name: 'Не все организаторы' }
      ]
    },
    eventTypeOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Тип' },
        { id: '1', name: '1 тип' }
      ]
    },
    yearOptions: {
      type: Array,
      default: () => [
        { id: '', name: 'Все года' },
      ]
    },
    audienceOptions: {
      type: Array,
      default: () => [
        {id:'', name: 'Вся аудитория'},
        {id:'1', name: 'Не Вся аудитория'},
      ]
    },
    initiativesOptions: {
      type: Array,
      default: () => [
        {id: '', name: 'все инициативы'},
        {id: '1', name: 'не все инициативы'},
      ]
    },
    initialFilters: {
      type: Array,
      default: () => [
        { name: "Все", filter: "all", isActive: true },
        { name: "Ближайшие", filter: "upcoming", isActive: false },
        { name: "Наиболее значимые федеральные", filter: "significant", isActive: false },
        { name: "Регулярные", filter: "regular", isActive: false },
        { name: "Дополнительные", filter: "additional", isActive: false },
        { name: "Прошедшие", filter: "past", isActive: false },
      ]
    },
    initialMonthFilters: {
      type: Array,
      default: () => [
        { name: "Все", filter: "all", isActive: true },
        { name: "Янв", filter: "", isActive: false },
        { name: "Фев", filter: "", isActive: false },
        { name: "Мар", filter: "", isActive: false },
        { name: "Апр", filter: "", isActive: false },
        { name: "Май", filter: "", isActive: false },
        { name: "Июн", filter: "", isActive: false },
        { name: "Июл", filter: "", isActive: false },
        { name: "Авг", filter: "", isActive: false },
        { name: "Сен", filter: "", isActive: false },
        { name: "Окт", filter: "", isActive: false },
        { name: "Ноя", filter: "", isActive: false },
        { name: "Дек", filter: "", isActive: false },
      ]
    },
    initialSelectedOptions: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    selectedMonths() {
      const result = [];
      this.monthFilters.map((item, key) => {
        if (item.isActive && key) {
          result.push(key);
        }
      });
      return result;
    },
    selectedFilters() {
      let result = "";
      this.filters.map(item => {
        if (item.isActive) {
          result = item.filter;
        }
      });
      return result;
    },
    otherFilterState() {
      return {
        filter: this.selectedFilters,
        month: this.selectedMonths,
      }
    },
    filterState() {
      return {
        ...this.otherFilterState,
        ...this.selectedOptions,
      };
    },

  },
  data() {
    return {
      bottomFiltersShowed: true,
      filters: [],
      monthFilters: [],
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      selectedOptions: {
        region: '',
        organizer: '',
        type: '',
        audience: '',
        year: '',
        initiative: '',
      },
    };
  },
  watch: {
    initialSelectedOptions: {
      handler() {
        if (isMatch(this.selectedOptions, this.initialSelectedOptions)) {
          return
        }

        this.updateOptions()
        this.emitChange()
      },
      deep: true
    }
  },
  created() {
    this.filters = this.initialFilters;
    this.monthFilters = this.initialMonthFilters;
    this.updateOptions()
    this.emitChange()
  },
  methods: {
    toggleFilter(el, i) {
      const filters = cloneDeep(this.filters)

      filters.forEach(item => {
        item.isActive = false;
      });

      filters[i].isActive = !this.filters[i].isActive;

      switch (el.filter) {
        case "all":
        case "significant":
        case "additional":
        case "upcoming":
        case "past":
        case "regular":
          this.bottomFiltersShowed = true;
          break;
        default:
          this.bottomFiltersShowed = true;
          break;
      }

      this.filters = filters

      this.emitChange()
    },
    toggleMonthFilter(el, i) {
      const indexClear = 0;
      const monthFilters = cloneDeep(this.monthFilters)

      monthFilters[i].isActive = !monthFilters[i].isActive;

      if (el.filter === "all") {
        monthFilters.forEach(item => {
          item.isActive = false;
        });
        monthFilters[indexClear].isActive = true;
      } else {
        if (monthFilters.some((item) => item.isActive)) {
          monthFilters[indexClear].isActive = false;
        } else {
          monthFilters[indexClear].isActive = true;
        }
      }

      this.monthFilters = monthFilters

      this.emitChange()
    },
    showMonth(monthIndex) {
      if (monthIndex === 0) {
        return true;
      }

      let currentFilter = "";
      let currentMonth = new Date().getMonth() + 1;
      let showMonth = true;

      this.filters.forEach(item => {
        if (item.isActive) {
          currentFilter = item.filter;
        }
      });

      switch (currentFilter) {
        case "upcoming":
          showMonth = currentMonth <= monthIndex;
          break;
        case "past":
          showMonth = currentMonth >= monthIndex;
          break;
      }

      return showMonth;
    },
    updateOptions() {
      this.selectedOptions = { ...this.selectedOptions, ...this.initialSelectedOptions }
    },
    onChangeSelect(name, value) {
      const selectedOptions = cloneDeep(this.selectedOptions)

      selectedOptions[name] = value

      if (name === 'region') {
        selectedOptions.organizer = ''
        this.$emit('region-change', true)
      }

      this.selectedOptions = selectedOptions

      this.emitChange()
    },
    emitChange() {
      this.$emit('change', this.filterState);
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events-filter';
$gup-into-list-x: 8px;
$gup-into-list-y: 16px;

$gup-month-list-y: 24px;

#{$b} {
  display: block;
  position: relative;
  z-index: 2;

  &__overflow {
    min-width: 100%;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    box-sizing: border-box;

    @include tablet {
      margin-left: #{-$container-pd-left-tablet};
      margin-right: #{-$container-pd-right-tablet};
      padding-left: $container-pd-left-tablet;
      padding-right: $container-pd-right-tablet;
    }

    @include mobile {
      margin-left: #{-$container-pd-left-mobile};
      margin-right: #{-$container-pd-right-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-right-mobile;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__top {
    margin-bottom: 41px;

    @include tablet {
      margin-bottom: 48px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;
    min-width: 100%;

    &>li {

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }


  &__item {
    cursor: pointer;
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.1em;
    color: rgba($color: $white-true, $alpha: 0.5);
    padding-bottom: 24px;
    white-space: nowrap;


    &::after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.is-active {
      color: $white-true;

      &::after {
        background-color: $color-base;
      }
    }
  }

  &__dropdowns {
    list-style: none;
    padding: 0;
    margin-top: -$gup-into-list-y;
    margin-bottom: -$gup-into-list-y;
    margin-left: -$gup-into-list-x;
    margin-right: -$gup-into-list-x;

    display: flex;
    flex-wrap: wrap;

    @include mobile {
      margin: 0;
    }
  }

  // .u-events-filter__dropdown-item
  &__dropdown-item{
    padding-top: $gup-into-list-y;
    padding-bottom: $gup-into-list-y;
    padding-left: $gup-into-list-x;
    padding-right: $gup-into-list-x;


    // .u-events-filter__dropdown-item--empty
    &--empty{
      @include tablet {
        display:none;
      }
    }

    &:nth-child(3n+1) ,
    &:nth-child(3n+2) {
      @include tablet-min {
        width: calc( (100% - (192px + ($gup-into-list-x * 2))) / 2);
      }

    }

    &:nth-child(3n+3){
      @include tablet-min {
        width: calc( 192px + ($gup-into-list-x * 2) );
      }
    }


    @include tablet {
      width: 50%;
    }

    @include mobile {
      width: 100%;
      padding: 0;

      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }

  &__dropdown {
    select {
      width: 100%;
    }
  }

  &__month-list {
    position: relative;
    font-family: $font-family-fugue;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-top: 64px;
    margin-bottom: -$gup-month-list-y;

    @include mobile {
      margin-top: 48px;
    }

    @include low-desktop {
      max-width: 650px;
    }

    &>li {
      margin-bottom: $gup-month-list-y;

      &:not(:last-child) {
        margin-right: 32px
      }
    }
    &:not(._without-first) {
      &>li {
        &:first-child {
          margin-right: 0;
          display: flex;
          align-items: center;

          &::after {
            content: "/";
            color: rgba($color-black, 0.5);
            margin: 0 24px;

          }
        }
      }
    }
  }

  &__month-item {
    cursor: pointer;
    user-select: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: $black-true;
    border-radius: 16px;
    transition: .2s ease;
    transition-property: color, background-color, padding;
    padding-top: 2px;

    &.is-active {
      padding-left: 8px;
      padding-right: 8px;
      background-color: $color-base;
      color: $white-true;
    }

    .inline-svg {
      width: 9px;
      height: 9px;
      margin: 0 0 1px 6px;
    }
  }
}
</style>
