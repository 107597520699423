<template>
  <UFigures
    :items="[
      {'name':'f-lk-region-plan','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
    ]"
    :fullWidth="true"
    :isOverflow="true"
    position="static"
  >
    <div class="lk-region-plan">
      <LkPlanDocuments
        v-if="Boolean(submitUrlPlan)"
        class="lk-region-plan__block"
        :validFormatFile="validFormatFile"
        :submitUrl="submitUrlPlan"
      />
      <LkPlanOffer
        v-if="Boolean(submitUrlEvent)"
        class="lk-region-plan__block"
        :initiativesOptions="initiativesOptions"
        :eventLevelOptions="eventLevelOptions"
        :regionOptions="regionOptions"
        :partnerOptions="partnerOptions"
        :meetingFormatOptions="meetingFormatOptions"
        :targetAudienceOptions="targetAudienceOptions"
        :submitUrl="submitUrlEvent"
        :eventObjectivesOptions="eventObjectivesOptions"
        :eventDrafts="dataEventDrafts"
        :isPartnerForm="isPartnerForm"
        :allowDraftsSaving="Boolean(submitUrlPlan)"
      />
    </div>
  </UFigures>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  props: {
    validFormatFile: {
      type: Array,
      default: () => [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'zip',
        'rar',
        '7z',
        'jar'
      ]
    },
    initiativesOptions: {
      type: Array,
      default: () => []
    },
    eventLevelOptions: {
      type: Array,
      default: () => []
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    partnerOptions: {
      type: Array,
      default: () => []
    },
    meetingFormatOptions: {
      type: Array,
      default: () => []
    },
    targetAudienceOptions: {
      type: Array,
      default: () => []
    },
    submitUrlPlan: {
      type: String,
      default: ''
    },
    submitUrlEvent: {
      type: String,
      default: ''
    },
    eventObjectivesOptions: {
      type: Array,
      default: () => []
    },
    eventDrafts: {
      type: Array,
      default: () => []
    },
    isPartnerForm: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dataEventDrafts: []
    }
  },

  methods: {
    async loadDrafts() {
      const response = await axios.get('/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=getDrafts');

      if (response.data.status === 'success') {
        this.dataEventDrafts = response.data.data.result_id;
      }
    }
  },

  async created() {
    if (this.eventDrafts) {
      this.dataEventDrafts = cloneDeep(this.eventDrafts)
    }
    await this.loadDrafts();
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.lk-region-plan';

#{$b} {
  position: relative;

  // .lk-region-plan__block
  &__block {
    &:not(:last-child) {
      margin-bottom: 64px;

      @include mobile {
        margin-bottom: 48px;
      }
    }
  }
}
</style>
