<template>
  <div
    class="u-event-picker"
    :class="{
      'u-event-picker--detail': isDetail,
      'is-collapse-opened': !isCollapsed,
      'is-calendar-showed': isCalendarShowed,
    }"
  >
    <div class="u-g-container">
      <div class="u-event-picker__inner">
        <div class="u-event-picker__calendar">
          <u-calendar
            :is-detail="isDetail"
            :prop-day="selectedDay"
            :subtitle="monthTheme"
            :event-days="eventDays"
            :is-visible-title="false"
            is-wait-change
            @change-month="onChangeMonth"
            @setdate="onSetDate"
          />
          <div v-if="!isDetail" class="u-event-picker__btn-actions g-hidden-tablet">
            <CustomButton
              tag="a"
              href="/science/"
              theme="primary"
              size="md"
            >
              Все события
            </CustomButton>
          </div>
        </div>
        <div
          v-for="(item, i) in filteredEvents"
          :key="i"
          class="u-event-picker__event"
          :class="{'is-dark': item.type === 'word_of_day' && !item.image, 'is-bg': item.type === 'word_of_day' && item.image }"
          :style="{
            backgroundImage: item.type === 'word_of_day' && item.image && !isDetail ? `url('${item.image}')` : ''
          }"
        >
          <div class="u-event-picker__event-wrapper">
            <div
              v-if="isDetail"
              class="u-event-picker__event-date"
              :class="{ 'is-showed': isToday }"
            >
              <div class="u-event-picker__event-label">Сегодня</div>
            </div>
            <div class="u-event-picker__event-head">
              <div class="u-event-picker__event-title">
                {{ item.type === 'science' ? 'Ни&nbsp;дня без науки' : 'Что-то на&nbsp;научном'}}
              </div>
              <div
                v-if="item.type === 'science'"
                class="u-event-picker__event-icon"
              >
                <img src="../assets/img/events/event-icon-1.png" alt="">
              </div>
            </div>
            <div class="u-event-picker__event-content">
              <div class="u-event-picker__event-info">
                <div class="u-event-picker__event-info_title" v-html="item.type === 'science' ? item.title : item.text" />
                <div class="u-event-picker__event-text" v-html="item.type === 'science' ? item.text : item.description" />
                <template v-if="isDetail">
                  <div class="u-event-picker__actions">
                    <transition name="fade-down">
                      <div class="u-event-picker__actions-inner" v-show="isCollapsed">
                        <a
                          v-if="item.hasDetail && !item.description"
                          class="button button--base"
                          :href="hrefWithRegion(item.detailUrl)"
                        >
                          Подробно
                        </a>
                        <div
                          v-if="item.description"
                          class="u-event-picker__collapse-button"
                          @click.prevent="toggleCollapse"
                        >
                          <span>Развернуть</span>
                          <inline-svg src="triangle-down" />
                        </div>
                      </div>
                    </transition>
                  </div>
                </template>
                <template v-else-if="item.type === 'science'">
                  <div class="u-event-picker__actions">
                    <div class="u-event-picker__actions-inner">
                      <CustomButton
                        tag="a"
                        size="md"
                        :href="hrefWithRegion(item.detailUrl)"
                        class="u-event-picker__actions-btn"
                      >
                        Узнать больше
                      </CustomButton>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isDetail">
          <div
            v-show="!isCalendarShowed"
            class="u-event-picker__toggle-calendar"
            @click.prevent="toggleCalendar"
          >
            <inline-svg src="menu-dots" />
            <span>Календарь</span>
          </div>
          <div
            v-show="isCalendarShowed"
            class="u-event-picker__close-calendar"
            @click.prevent="toggleCalendar"
          >
            <inline-svg src="close" />
          </div>
        </template>
      </div>
      <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <div class="u-event-picker__desc" v-show="!isCollapsed">
          <div class="u-event-picker__desc-inner">
            <div class="u-event-picker__desc-content">
              <div class="u-event-picker__desc-title">{{ dateString }}</div>
              <div class="u-event-picker__desc-text" v-html="description"/>
              <div class="u-event-picker__actions">
                <div class="u-event-picker__actions-inner">
                  <div
                    class="u-event-picker__collapse-button is-open"
                    @click.prevent="toggleCollapse"
                  >
                    <span>Свернуть</span>
                    <inline-svg src="triangle-down" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="image" class="u-event-picker__desc-media">
              <div
                class="u-event-picker__desc-image"
                :style="`background-image: url('${image}')`"
              ></div>
            </div>
          </div>
        </div>
      </transition>
      <div v-if="!isDetail" class="u-event-picker__btn-actions g-visible-tablet">
        <CustomButton
          tag="a"
          :href="hrefWithRegion('/science/')"
          theme="primary"
          size="md"
        >
          Все события
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

const TODAY = new Date();
export default {
  name: "EventPicker",
  props: {
    url: {
      type: String,
      required: true
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    initData: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      items: [
        {
          date: "2021-03-25", // Год, месяц, число
          title: "14 марта",
          text:
            "Челябинские ученые разрабатывают технологию безотходной переработки медьсодержащих шлаков."
        }
      ],
      monthTheme: "",
      dateString: "",
      title: "",
      text: "",
      description: "",
      image: "",
      detailUrl: "",
      hasDetail: false,
      selectedDay: TODAY,
      isCollapsed: true,
      isCalendarShowed: false,
      filteredEvents: [],
    };
  },
  computed: {
    monthItemsScience() {
      const data = {};
      this.items.map(item => {
        if (item.type === 'science') {
          data[item.date] = item
        }
      });
      return data;
    },
    monthItemsWord() {
      const data = {};
      this.items.map(item => {
        if (item.type === 'word_of_day') {
          data[item.date] = item
        }
      });
      return data;
    },
    eventDays() {
      return this.items.map(item => {
        return item.date;
      });
    },
    isToday() {
      const today = moment(TODAY);
      return moment(this.selectedDay).isSame(today, 'day');
    }
  },
  async mounted() {
    if (this.isDetail && !Array.isArray(this.initData)) {
      this.dateString = this.initData.dateString;
      this.title = this.initData.title;
      this.text = this.initData.text;
      this.image = this.initData.image;
      this.detailUrl = this.initData.detailUrl;
      this.hasDetail = this.initData.hasDetail;
      this.description = this.initData.description;
    }
    await this.onChangeMonth(TODAY.getMonth() + 1, TODAY.getFullYear());
    this.onSetDate(TODAY);
    this.selectedDay = TODAY;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleCalendar() {
      this.isCalendarShowed = !this.isCalendarShowed;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    getFormatDate(date, format) {
      return date
        ? moment(date)
          .locale("ru")
          .format(format)
        : "";
    },
    async onChangeMonth(month, year) {
      const res = await axios.get(this.url + "&month=" + month + "&year=" + year);
      this.items = res.data.data.items;
      this.monthTheme = res.data.data.monthTheme;
      return res;
    },
    onSetDate(date) {
      this.filteredEvents = [];
      this.selectedDay = date;
      const dateStr = moment(date).format("YYYY-MM-DD");
      const eventScience = this.monthItemsScience[dateStr];
      const eventWord = this.monthItemsWord[dateStr];

      this.isCollapsed = true;

      if (eventScience) {
        this.filteredEvents.push(eventScience);
      }

      if (eventWord) {
        this.filteredEvents.push(eventWord);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/u-includes";

$b: ".u-event-picker";

#{$b} {
  display: block;

  &__inner {
    display: flex;
    margin-right: -16px;

    @include tablet {
      flex-direction: column;
      align-items: center;
      margin: -8px;
      padding: 8px;
    }

    #{$b}--detail & {
      align-items: stretch;
      height: 100%;
      min-height: 408px;

      @include low-desktop {
        position: relative;
        margin-right: $container-pd-right-md;
      }

      @include tablet {
        margin-right: $container-pd-right-tablet;
      }

      @include mobile {
        min-height: 302px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: $container-pd-right-mobile;
      }
    }
  }

  &__event {
    width: calc(100% - 416px);
    position: relative;
    display: flex;
    border-radius: 24px;
    @include bg-cover;
    color: $white-true;
    background-image: url("../assets/img/event-default-bg-2.png");
    margin-right: 16px;
    overflow: hidden;

    @include tablet {
      min-height: 300px;
    }

    &.is-bg {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
    }

    &.is-dark {
      background-image: url("../assets/img/event-dark-bg.png");
    }

    &.is-light {
      color: $color-black;
    }

    @include tablet {
      min-width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }

    #{$b}--detail & {
      background: transparent;

      @include low-desktop {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease;
      }
    }

    .is-calendar-showed & {
      opacity: 0;
      visibility: hidden;
    }

    &-info {
      display: flex;
      flex-direction: column;
      max-width: 250px;

      @include tablet {
        max-width: 100%;
      }

      &_title {
        font-family: "Fugue", Arial, sans-serif;
        font-weight: 500;
        font-size: 36px;
        line-height: 1;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 20px;

        @include desktop {
          font-size: 28px;
        }

        @include mobile {
          font-size: 26px;
        }
      }
    }

    &-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 1;
    }

    &-icon {
      position: absolute;
      top: 0;
      right: 8px;

      @include desktop {
        top: -5px;
        right: -5px;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 14px 28px 23px 28px;
      justify-content: space-between;

      @include tablet {
        padding: 23px 28px;
      }

      #{$b}--detail & {
        background: none;
        min-height: auto;
        padding: 0 30px 50px 0;

        @include mobile {
          padding: 0 0 40px 0;
        }
      }
    }

    &-date {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease;

      &.is-showed {
        opacity: 1;
        visibility: visible;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include tablet {
          margin-bottom: 25px;
        }

        @include mobile {
          margin-bottom: 15px;
        }
      }
    }

    &-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      border-radius: 30px;
      border: 1.5px solid currentColor;
      padding: 0 14px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      @include mobile {
        height: 24px;
        padding: 0 10px;
        border-width: 1px;
        font-size: 10px;
      }
    }

    &-head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-title {
      width: 60%;
      min-height: 1em;
      position: relative;
      z-index: 0;
      font-weight: 500;
      font-size: 28px;
      line-height: 1;
      margin: 0 0 20px;
      color: #ffffff;
      font-family: $font-family-fugue;
      text-transform: uppercase;
      word-spacing: 0;

      @include tablet {
        width: 100%;
        font-size: 36px;
      }

      @include mobile {
        width: 80%;
        font-size: 30px;
      }

      #{$b}--detail & {
        color: $white-true;
        font-size: 90px;
        line-height: 1;
        margin: 0 0 30px;

        @supports (-webkit-text-stroke: 1px $white-true) {
          -webkit-text-stroke: 1.5px $white-true;
          -webkit-text-fill-color: transparent;
        }

        @include desktop {
          margin-bottom: 25px;
        }

        @include mobile {
          font-size: 36px;
          margin-bottom: 10px;
        }
      }
    }

    &-text {
      margin-top: auto;
      font-size: 16px;
      margin-bottom: 24px;
      line-height: 21px;
      color: $white-true;

      @include desktop {
        font-size: 20px;
      }

      @include tablet {
        max-width: 496px;
        line-height: 1.3;
      }

      @include mobile {
        font-size: 16px;
      }

      #{$b}--detail & {
        max-width: 100%;
        font-size: 40px;
        line-height: 1.3;

        @include tablet {
          font-size: 34px;
        }

        @include mobile {
          font-size: 16px;
          line-height: 1.6;
        }

        &:not(:last-child) {
          margin-bottom: 40px;

          @include desktop {
            margin-bottom: 35px;
          }

          @include tablet {
            margin-bottom: 25px;
          }

          @include mobile {
            margin-bottom: 20px;
          }
        }
      }

      a {
        color: inherit;
        text-decoration-line: underline;
        transition: $transtion-default;

        @include hover {
          opacity: 0.75;
        }
      }
    }
  }

  &__calendar {
    padding: 25px 26px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px rgba(11, 32, 95, 0.1);
    border-radius: 16px;
    margin-right: 16px;

    @include tablet {
      margin: 0 0 1rem;
      padding: 30px 60px 60px;
    }

    @include mobile {
      width: 100%;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      margin: 0;
    }

    #{$b}--detail & {
      background: linear-gradient(180deg, rgba(16, 33, 80, 0) 0%, #102150 100%);
      margin: -120px 0 0;
      padding: 126px 60px 55px;

      @include low-desktop {
        margin: 0 0 0 auto;
        padding: 5px 0 0;
        width: 450px;
        position: absolute;
        top: 0;
        right: 100px;
        bottom: 0;
        left: 0;
        z-index: 10;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease;
      }

      @include tablet {
        width: 468px;
        right: 92px;
      }

      @include mobile {
        display: none;
      }
    }

    .is-calendar-showed & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__desc {
    transition: height 0.3s ease-out;

    &-inner {
      display: flex;
      border-top: 1px solid $color-base;
      padding: 80px 0;

      @include low-desktop {
        padding: 60px 0;
      }

      @include tablet {
        padding: 50px $container-pd-right-tablet 60px 0;
      }

      @include mobile {
        padding: 32px $container-pd-right-mobile 40px 0;
      }
    }

    &-content {
      width: 100%;
      padding-right: 80px;

      @include low-desktop {
        padding-right: 30px;
      }

      @include tablet {
        padding-right: 0;
      }
    }

    &-media {
      flex-shrink: 0;
      width: 586px;

      @include desktop {
        width: 500px;
      }

      @include low-desktop {
        width: 300px;
      }

      @include tablet {
        display: none;
      }
    }

    &-title {
      position: relative;
      display: block;
      font-weight: 400;
      font-size: 22px;
      line-height: 1.5;
      margin: 0;
      z-index: 1;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-text {
      &:not(:last-child) {
        margin-bottom: 46px;

        @include mobile {
          margin-bottom: 30px;
        }
      }

      p {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .content-panel {
        background: transparent;
        padding: 0;
        backdrop-filter: none;
        box-shadow: none;
        margin: 0;
      }

      .content-container {
        max-width: 100%;
      }
    }

    &-image {
      height: 100%;
      min-height: 496px;
      display: block;
      @include bg-cover;
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    overflow: hidden;
  }

  &__actions {
    &-inner {
      display: flex;
    }

    &-btn {
      @include mobile {
        padding: 5px 13px;
        height: 30px;
        font-size: 12px;
        line-height: 130%;
      }
    }

    #{$b}--detail & {
      min-height: 60px;
      overflow: hidden;

      @include mobile {
        min-height: 50px;
      }

      &-inner {
        display: flex;
        align-items: center;
        transition: 0.2s ease-in;
        transition-property: opacity, transform;

        &.fade-down-enter,
        &.fade-down-leave-to {
          opacity: 0;
          transform: translateY(100%);
        }

        &>* {
          &:not(:last-child) {
            margin-right: 40px;

            @include mobile {
              margin-right: 20px;
            }
          }
        }
      }

      .button {
        min-width: 266px;

        @include mobile {
          min-width: 190px;
        }
      }
    }
  }

  &__collapse-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include mobile {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid $color-base;
    }

    &>span {
      @include mobile {
        display: none;
      }
    }

    &.is-open {

      .inline-svg {
        transform: rotateX(-180deg);
      }
    }

    .inline-svg {
      width: 7px;
      height: 7px;
      transition: transform 0.2s ease;

      &:not(:first-child) {
        margin-left: 10px;

        @include mobile {
          margin: auto;
        }
      }
    }
  }

  &__toggle-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    cursor: pointer;
    height: 30px;
    padding: 0 13px 0 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-base;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: 1.5px solid $color-base;
    border-radius: 30px;

    @include low-desktop-min {
      display: none;
    }

    @include mobile {
      display: none;
    }

    .inline-svg {
      width: 18px;
      height: 8px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__close-calendar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-base;
    border: 1.5px solid $color-base;
    border-radius: 30px;

    .inline-svg {
      width: 10px;
      height: 10px;
    }
  }

  &__btn-actions {
    position: relative;
    margin-top: 23px;
    top: 1px;

    @include tablet {
      text-align: center;
    }

    @include mobile {
      margin-top: 24px;
    }

    .custom-button--size-md {
      padding: 10px 34px;
    }
  }
}
</style>
