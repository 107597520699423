<template>
  <section class="u-banner">
    <div class="u-g-container" v-if="isUpdatedSwiper">
      <div class="u-banner__inner">
        <swiper
          ref="bannerSwiper"
          :options="swiperOptions"
          class="u-banner__swiper"
        >
          <swiper-slide
            v-for="(item,i) in bannerList"
            :key="`u-banner__swiper-slide-${i}`"
            class="u-banner__swiper-slide"
          >
            <a
              class="u-banner-item"
              :href="item.target !== '_blank' ? hrefWithRegion(item.url) : item.url"
              :target="item.target"
              :rel="item.rel ? item.rel : null"
            >
              <div class="u-banner-item__info">
                <div class="u-banner-item__info-item" v-html="item.info" />
                <CustomButton
                  class="u-banner-item__btn"
                  theme="primary"
                  size="ml"
                >
                  {{ item.btnText }}
                </CustomButton>
              </div>
              <div class="u-banner-item__preview">
                <img
                  class="u-banner-item__img"
                  :src="item.image"
                >
              </div>
            </a>
          </swiper-slide>
        </swiper>
        <div class="u-banner__swiper-controls" v-if="bannerList.length > 1">
          <div class="u-swiper-controls u-swiper-controls--primary">
            <button class="u-swiper-controls__btn u-swiper-controls__btn--prev u-banner__prev" />
            <div class="u-swiper-controls__count u-banner__controls" />
            <button class="u-swiper-controls__btn u-swiper-controls__btn--next u-banner__next" />
          </div>
        </div>
        <div class="u-banner__swiper-pagination-mob" v-if="bannerList.length > 1">
          <div class="u-swiper-pagination u-banner__pagination" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    bannerList: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: [Object, Boolean],
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUpdatedSwiper: true,
      paginationEl: '.u-banner__controls'
    }
  },
  methods: {
    updateSwiper() {
      this.isUpdatedSwiper = false
      this.setPaginationEl();
      this.$nextTick(() => {
        this.isUpdatedSwiper = true;
      });
    },
    setPaginationEl() {
      if (window.innerWidth > 1023) {
        this.paginationEl = '.u-banner__controls'
      } else {
        this.paginationEl = '.u-banner__pagination'
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.bannerSwiper.$swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: this.isSingleSlide ? 1 : 1.06,
        spaceBetween: 16,
        navigation: {
          prevEl: '.u-banner__prev',
          nextEl: '.u-banner__next',
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        loop: this.loop,
        autoplay: this.autoplay ? {...this.autoplay, disableOnInteraction: false} : false,
        pagination: {
          el: this.paginationEl,
          type: "bullets",
          bulletClass: "u-swiper-pagination__bullet",
          bulletActiveClass: "u-swiper-pagination__bullet--is-active",
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 1,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
        }
      }
    },
    isSingleSlide() {
      return this.bannerList.length === 1
    }
  },
  mounted() {
    this.isUpdatedSwiper = false;
    window.addEventListener('resize', this.updateSwiper);
    this.updateSwiper();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSwiper);
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.u-banner';

#{$b} {
  font-family: $font-family-inter;

  // .u-banner__inner
  &__inner {
    position: relative;
  }

  // .u-banner__swiper-controls
  &__swiper-controls {
    position: absolute;
    right: 640px;
    bottom: 34px;
    z-index: 2;

    @include low-desktop {
      right: calc(50% + 24px);
    }

    @include tablet {
      display: none;
    }
  }

  // .u-banner__swiper-pagination-mob
  &__swiper-pagination-mob {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    @include tablet-min {
      display: none;
    }
  }

  .swiper-container {
    padding: 10px 15px;
    margin: 0 -15px;

    @include tablet-only {
      padding-right: $container-pd-right-tablet;
      margin-right: -$container-pd-right-tablet;
    }

    @include mobile {
      overflow: unset;
    }
  }

  .swiper-slide {
    height: auto;
  }
}

$c: '.u-banner-item';

#{$c} {
  height: 100%;
  font-family: $font-family-inter;
  display: grid;
  grid-template-columns: auto 608px;
  align-items: center;
  text-decoration: none;
  background-color: $white-true;
  border-radius: 16px;
  padding: 8px;
  overflow: hidden;
  transition: $transtion-default;
  cursor: pointer;

  @include low-desktop {
    grid-template-columns: 1fr 1fr;
  }

  @include tablet {
    grid-template-columns: 1fr;
    grid-template-rows: calc(100vw / 3.40) auto;
    align-items: unset;
  }

  &:hover {
    @include tablet-min {
      transform: scale(1.01)
    }
  }

  // .u-banner-item__info
  &__info {
    height: 100%;
    color: $black-true;
    padding: 16px 24px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      padding: 17px 12px 12px 12px;
    }

    h3 {
      font-family: $font-family-fugue;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 100%;
      text-transform: uppercase;
      margin: 0 0 8px 0;

      @include mobile {
        font-size: 20px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      margin: 0;

      @include low-desktop {
        font-size: 14px;
        letter-spacing: -0.02em;
      }
    }

    a:not([class]) {
      cursor: pointer;
      color: $color-base;
      text-decoration: none;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  // .u-banner-item__btn
  &__btn {
    margin-top: 16px;
    align-self: flex-start;
  }

  // .u-banner-item__preview
  &__preview {
    width: 100%;
    height: 190px;
    border-radius: 8px;
    overflow: hidden;

    @include low-desktop {
      height: auto;
      aspect-ratio: 608/190;
    }

    @include tablet {
      width: 100%;
      height: 100%;
      order: -1;
    }
  }

  // .u-banner-item__img
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
