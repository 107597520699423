<template>
  <div
    class="burger-menu"
    :class="{ 'burger-menu--showed': IS_OPENED_MENU }"
  >
    <transition name="fade">
      <div
        v-show="IS_OPENED_MENU"
        class="burger-menu__wrapper"
      >
        <div class="burger-menu__wrapper-outter">
          <div class="u-g-container">
            <div class="burger-menu__wrapper-inner">
              <LocationBtn
                v-if="isExistRegion"
                class="burger-menu__location"
                v-bind="regionProps"
                mode="burger"
              />
              <div class="burger-menu__search">
                <HeaderSearch
                  class="burger-menu__header-search"
                  v-if="breakpoint.isMobile"
                  :isShowAllways="true"
                  :forMobile="true"
                  action-search="/search/"
                />
              </div>
              <div class="burger-menu__user g-visible-mobile">
                <BtnLogin
                  v-if="!isAuth && isCanAuth"
                  class="burger-menu__btn-login"
                />
                <UserBlock
                  v-if="isAuth"
                  class="header__actions-item header__user-block"
                  :lk-url="lkUrl"
                  :logout-url="logoutUrl"
                />
              </div>
              <CustomButton
                class="burger-menu__btn-event"
                theme="primary"
                size="md"
                :tag="isAuth ? 'a' : 'button'"
                :href="isAuth ? '/lk/?tab=add-event' : ''"
                @click="onClickEventbtn"
              >
                Предложить мероприятие
              </CustomButton>
              <div class="burger-menu__search">
                <HeaderSearch
                  class="burger-menu__header-search"
                  v-if="breakpoint.isMobile"
                  :isShowAllways="true"
                  :forMobile="true"
                  action-search="/search/"
                />
              </div>
              <div class="burger-menu__grid">
                <div class="burger-menu__grid-main">
                  <HeaderMenu mode="burger" />
                </div>
                <div v-if="SECONDARY_ITEMS?.length" class="burger-menu__grid-secondary">
                  <div v-if="breakpoint.isDesktop" class="burger-menu__navigation">
                    <div class="burger-menu__navigation-list">
                      <div
                        v-for="item in SECONDARY_ITEMS"
                        :key="`burger-menu__navigation-item-${item.id}`"
                        class="burger-menu__navigation-item"
                      >
                        <CustomButton
                          v-if="item.isChild"
                          theme="child-primary"
                          size="md"
                          :href="item.url"
                          :target="item.target"
                          tag="a"
                        >
                          <span v-html="item.name" />
                        </CustomButton>
                        <a
                          v-else
                          :href="item.url"
                          :target="item.target"
                          class="burger-menu__navigation-link tt-uppercase"
                          v-html="item.name"
                        />
                      </div>
                    </div>
                  </div>
                  <NavigationBlock v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import lockPageScroll from '@/components/helpers/lockPageScroll.js'

export default {
  inject: ['breakpoint', 'goToAuthForm'],
  name: 'BurgerMenu',
  props: {
    isAuth: {
      type: Boolean,
      default: false
    },
    lkUrl: {
      type: String,
      default: ''
    },
    logoutUrl: {
      type: String,
      default: ''
    },
    isCanAuth: {
      type: Boolean,
      default: true
    },
    regionProps: {
      type: Object,
      default: () => ({})
    },
    mainItems: {
      type: Array,
      default: () => []
    },
    secondaryItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      htmlBody: undefined,
      htmlHeader: undefined
    };
  },
  created() {
    this.saveInStore();
  },
  mounted() {
    this.htmlBody = document.querySelector('html');
    this.htmlMain = document.querySelector('#main');
    this.htmlHeader = document.querySelector('.header__container');
  },
  computed: {
    ...mapGetters('header', ['IS_OPENED_MENU', 'MAIN_ITEMS', 'SECONDARY_ITEMS']),
    isExistRegion() {
      return this.regionProps && Object.keys(this.regionProps).some(Boolean)
    }
  },
  methods: {
    ...mapMutations('header', ['SET_IS_OPENED_MENU', 'SET_MAIN_ITEMS', 'SET_SECONDARY_ITEMS']),
    toggleMenu() {
      this.onToggleMenu(this.IS_OPENED_MENU);
      lockPageScroll(this.IS_OPENED_MENU, 'burger-menu')
    },
    onToggleMenu(state) {
      if (state) {
        setTimeout(() => {
          this.htmlHeader.classList.add('--menu');
          this.htmlBody.classList.add('burger-is-opened');
        }, 50);
      } else {
        this.htmlHeader.classList.remove('--menu');
        this.htmlBody.classList.remove('burger-is-opened');
      }
    },
    onClickEventbtn() {
      if (this.isAuth) {
        return
      }

      this.SET_IS_OPENED_MENU(false)
      this.goToAuthForm('add-event')
    },
    saveInStore() {
      if (this.mainItems) {
        this.SET_MAIN_ITEMS(this.mainItems)
      }
      if (this.secondaryItems) {
        this.SET_SECONDARY_ITEMS(this.secondaryItems)
      }
    }
  },
  watch: {
    IS_OPENED_MENU() {
      this.toggleMenu()
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: ".burger-menu";

#{$b} {
  &__wrapper {
    height: var(--app-height);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    color: $white-true;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include low-desktop {
      padding-right: 0;
    }

    &-outter {
      position: relative;
      height: calc(100% + 1px);
      padding-top: var(--header-height);

      &:before {
        position: fixed;
        content: '';
        background: $color-dark;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
      }

      @include mobile {
        padding-top: 22px;
      }
    }

    &-inner {
      position: relative;
      display: block;
      min-height: 100%;
      height: 100%;
      padding-top: 80px;
      padding-bottom: 80px;
      z-index: 55;

      @include mobile {
        padding-top: 0;
        padding-bottom: 60px;
      }
    }
  }

  #{$b}__location {
    margin-bottom: 24px;
  }

  // .burger-menu__grid
  &__grid {
    display: grid;
    grid-template-columns: auto 406px;
    gap: 60px;
    padding: 0 2px;

    @include low-desktop {
      grid-template-columns: auto 330px;
      gap: 24px;
    }

    @include tablet {
      display: block;
    }

    // .burger-menu__grid-main
    &-main {
      position: relative;
      padding-right: 60px;

      @include low-desktop {
        padding-right: 24px;
      }

      @include tablet {
        padding-right: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: -40px;
        right: 0;
        height: calc(40px + 100%);
        width: 1px;
        background-color: rgba($white-true, 0.25);

        @include tablet {
          display: none;
        }
      }
    }

    // .burger-menu__grid-secondary
    &-secondary {
      @include tablet {
        margin-top: 48px;
      }
    }
  }

  // .burger-menu__user
  &__user {
    margin-bottom: 30px;
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      &:not(:last-child) {
        margin-bottom: 2rem;

        @include mobile {
          margin-bottom: 24px;
        }
      }
    }

    &-link {
      font-family: $font-family-fugue;
      display: inline-block;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
      font-size: 28px;
      line-height: 1.4;
      transition: color .3s ease;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $white-true;
        transition: $transtion-default;
        transform: scale(0);
      }

      @include desktop {
        font-size: 22px;
      }

      &:hover,
      &:focus {
        text-decoration:none;
        &::after{
          transform: scale(1);
        }
      }
    }
  }

  &__top {
    position: relative;
    top: -20px;
    display: flex;
    justify-content: space-between;
    min-height: 21px;
  }

  &__search {
    width: 100%;
    position: relative;
    z-index: 1000;
    margin-bottom: 24px;

    @include mobile-min {
      display: none;
    }
  }

  &__header-search {
    width: 100% !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: 0.2s;
    transition-property: opacity;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .burger-menu-button {
    position: absolute;
    top: -31px;
    right: 0;

    @include mobile {
      top: -41px;
      right: 4px;
    }

    &__close {
      display: flex;
    }
  }

  // .burger-menu__navigation
  &__navigation {

    // .burger-menu__navigation-item
    &-item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    // .burger-menu__navigation-link
    &-link {
      font-family: $font-family-fugue;
      font-size: 24px;
      line-height: 100%;
      font-weight: 500;
      color: $white-true;
      text-decoration: none;
      cursor: pointer;
      transition: $transtion-default;

      @include hover {
        color: $color-base-origin;
      }

      br {
        display: none;
      }
    }
  }

  // .burger-menu__btn-event
  &__btn-event {
    width: 100%;
    margin-bottom: 16px;

    @include mobile-min {
      display: none !important;
    }
  }
}
</style>
