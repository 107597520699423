<template>
  <article class="custom-video-card" :class="{'custom-video-card--is-large-tablet': cardData.isLargeTablet}">
    <component :is="innerComponentName" :href="hrefWithRegion(cardData.url)" class="custom-video-card__inner">
      <div
        v-if="isExistsVideo || isExistsCover"
        class="custom-video-card__preview"
        @click.stop="openVideo"
      >
        <div v-if="isExistsVideo" class="custom-video-card__play">
          <InlineSvg class="custom-video-card__play-icon" src="play-icon" />
        </div>
        <img
          v-if="isExistsCover"
          :src="cardData.cover"
          :alt="cardData.title"
          class="custom-video-card__preview-img"
        >
      </div>
      <component :is="bodyComponentName" :href="hrefWithRegion(cardData.url)" class="custom-video-card__body">
        <h4
          class="custom-video-card__title tt-uppercase title-md"
          :style="`--maxLine:${maxLine}`"
        >
          {{ cardData.title }}
        </h4>
        <CustomButton class="custom-video-card__btn">
          Узнать больше
        </CustomButton>
      </component>
    </component>
  </article>
</template>

<script>

export default {
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isExistsVideo() {
      return Boolean(this.cardData) && Boolean(this.cardData.src)
    },
    isExistsCover() {
      return Boolean(this.cardData) && Boolean(this.cardData.cover)
    },
    maxLine() {
      return this.isExistsVideo ? 3 : 6
    },
    innerComponentName() {
      return this.isExistsVideo ? 'div' : 'a'
    },
    bodyComponentName() {
      return !this.isExistsVideo ? 'div' : 'a'
    },
  },
  methods: {
    openVideo() {
      if (!this.isExistsVideo) {
        return
      }
      this.$root.$emit('openPopupGallery', 0, [this.cardData], { closeOnClickOutsideMobile: true })
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.custom-video-card';

#{$b} {
  height: 100%;
  width: 100%;
  color: $white-true;
  transition: $transtion-default;
  cursor: pointer;
  transition: $transtion-default;

  &:hover {
    @include mobile-min {
      transform: scale(1.02);
    }
  }

  // .custom-video-card--is-large-tablet
  &--is-large-tablet {
    @include tablet-only {
      #{$b}__inner {
        flex-direction: row;
        aspect-ratio: unset;
        min-height: 330px;
      }
      #{$b}__preview {
        width: 50%;
        min-width: 50%;
        height: auto;

        &-img {
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
          border-top-left-radius: 16px;
          border-top-right-radius: 0;
          border-bottom-left-radius: 16px;
        }
      }
      #{$b}__body {
        padding: 24px 24px 24px 32px;
      }
      #{$b}__title {
        @include lineClamp(6);
      }
    }
  }

  // .custom-video-card__inner
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: inherit;
    justify-content: space-between;
    border-radius: 16px;
    background-color: $color-base;
    overflow: hidden;
    text-decoration: none;
    aspect-ratio: 1/1;

    @include mobile {
      min-height: 330px;
      aspect-ratio: unset;
    }


  }

  // .custom-video-card__preview
  &__preview {
    position: relative;
    width: 100%;

    &:hover {
      @include mobile-min {
        #{$b}__play-icon {
          color: $color-base-origin;
        }
      }
    }

    // .custom-video-card__preview-img
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 2/1;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      filter: brightness(0.9);
    }
  }

  // .custom-video-card__body
  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 24px;
    margin-top: auto;
    color: inherit;
    text-decoration: none;
  }

  // .custom-video-card__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0 0 24px 0;
    @include lineClamp(var(--maxLine));

    @include low-desktop {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  // .custom-video-card__play
  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 48px;
    height: 48px;
    padding: 6px;
    z-index: 1;

    // .custom-video-card__play-icon
    &-icon {
      transition: $transtion-default;
    }
  }

  // .custom-video-card__btn
  &__btn {
    align-self: flex-start;
  }
}
</style>
