<template>
  <div class="lk-plan-form" ref="lkPlanForm" :class="{ 'lk-plan-form--is-loading': isLoadingGetEvent }">
    <div class="lk-plan-form__header" v-if="title">
      <h2 class="lk-plan-form__title tt-uppercase">
        {{ title }}
      </h2>
    </div>
    <div v-if="isCreate && drafts.length" class="lk-plan-form__draft">
      <div class="lk-plan-form__draft-caption">
        Вы можете выбрать форму из черновика
      </div>

      <div class="lk-plan-form__fields">
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Черновик
          </div>
          <div class="lk-plan-form__select-wrapper">
            <v-select
              v-model="draftModel"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="draftsOptions"
              placeholder="Выберите черновик"
              @input="onSelectDraft"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <ErrorFormWrapper
        class="lk-plan-form__feedback-errors"
        :isVisible="Boolean(errorsAfterGetEvent?.length)"
      >
        <div
          v-for="(error, i) in errorsAfterGetEvent"
          :key="`lk-plan-form__feedback-error-${error.code}-${i}`"
          class="lk-plan-form__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
    </div>
    <div class="lk-plan-form__body">
      <div
        class="lk-plan-form__fields"
        :class="{ 'lk-plan-form__fields--gap-large': isInvalidForm }"
      >
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Инициатива*
          </div>
          <div class="lk-plan-form__select-wrapper">
            <v-select
              v-model="form.initiative"
              :class="{ 'vs--error': isRequiredErrorField('initiative') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="initiativesOptions"
              placeholder="Выберите инициативу, к которой относится заявляемое событие"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('initiative')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="eventName"
            label="Название события*"
            v-model="form.eventName"
            placeholder="Например, Всероссийский фестиваль науки «НАУКА 0+»"
            :error="isRequiredErrorField('eventName')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('eventName')"
            class="lk-plan-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <PartnerCreaterBlock
            label="Организатор*"
            placeholder="Найдите организатора по ИНН"
            :error="isRequiredErrorField('organizer') ? 'Обязательное поле' : ''"
            v-model="form.organizer"
          />
        </div>
        <div class="lk-plan-form__field">
          <PartnerCreaterBlock
            label="Соисполнители"
            placeholder="Найдите соисполнителя по ИНН"
            v-model="form.coExecutors"
          />
        </div>
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            {{ isEventForm ? "Уровень события*" : "Уровень (масштаб) проекта*" }}
          </div>
          <div class="lk-plan-form__select-wrapper">
            <v-select
              v-model="form.eventLevel"
              :class="{ 'vs--error': isRequiredErrorField('eventLevel') }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="eventLevelOptions"
              placeholder="Выберите уровень"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('eventLevel')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Регион*
          </div>
          <div class="lk-plan-form__select-wrapper" :class="{ 'u-select--no-interactive': regionOptions.length <= 1 }">
            <v-select
              v-model="form.region"
              :class="{
                'vs--error': isRequiredErrorField('region')
              }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.name"
              :options="regionOptions"
              placeholder="Выберите регион"
              multiple
              @input="onChangeRegion"
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('region')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="lk-plan-form__field">
          <CustomCalendar
            v-model="form.dateFrom"
            :minDate="minDate"
            :maxDate="form.dateTo"
            subtitle="Дата начала события*"
            isFormTheme
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('dateFrom')"
            class="lk-plan-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <CustomCalendar
            v-model="form.dateTo"
            :minDate="form.dateFrom"
            :maxDate="maxDate"
            subtitle="Дата окончания события*"
            isFormTheme
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('dateTo')"
            class="lk-plan-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div v-if="isEventForm" class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="venue"
            label="Место проведения*"
            v-model="form.venue"
            placeholder="Например, 6 центральных региональных площадок, 1 главная площадка"
            :error="isRequiredErrorField('venue')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('venue')"
            class="lk-plan-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Формат события*
          </div>
          <div class="lk-plan-form__select-wrapper" :class="{ 'u-select--no-interactive': meetingFormatOptions.length <= 1 }">
            <v-select
              v-model="form.meetingFormat"
              :class="{
                'vs--error': isRequiredErrorField('meetingFormat')
              }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="meetingFormatOptions"
              placeholder="..."
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('meetingFormat')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div v-if="isEventForm" class="lk-plan-form__field">
          <div class="lk-plan-form__field">
            <UTextInput
              class="u-text-input--smaller"
              name="siteLink"
              label="Ссылка на сайт"
              v-model="form.siteLink"
              placeholder="..."
            />
          </div>
        </div>
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Целевая аудитория*
          </div>
          <div class="lk-plan-form__select-wrapper" :class="{ 'u-select--no-interactive': targetAudienceOptions.length <= 1 }">
            <v-select
              v-model="form.targetAudience"
              :class="{
                'vs--error': isRequiredErrorField('targetAudience')
              }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="targetAudienceOptions"
              placeholder="..."
              multiple
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('targetAudience')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="announcement"
            v-model="form.announcement"
            placeholder="Введите краткое описание"
            :error="isRequiredErrorField('announcement') || isMaxLengthErrorField('announcement')"
            isTextArea
            textAreaSize="lg"
          >
            <template #label>
              <div class="lk-plan-form__field-label">
                <div>
                  Анонс*
                </div>
                <div class="lk-plan-form__field-length" :class="{ 'lk-plan-form__field-length--is-error': isMaxLengthErrorField('announcement') }">
                  {{ getLengthInfo('announcement') }}
                </div>
              </div>
            </template>
          </UTextInput>
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('announcement') || isMaxLengthErrorField('announcement')"
            class="lk-plan-form__error-field"
          >
            <div v-show="isRequiredErrorField('announcement')">Обязательное поле</div>
            <div v-show="isMaxLengthErrorField('announcement')">Максимальное количество символов: {{ getMaxLengthValidate('announcement') }}</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="detailInfo"
            label="Подробно*"
            v-model="form.detailInfo"
            placeholder="Введите подробное описание"
            :error="isRequiredErrorField('detailInfo')"
            isTextArea
            textAreaSize="lg"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('detailInfo')"
            class="lk-plan-form__error-field"
          >
            <div>Обязательное поле</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="otherComment"
            label="Комментарий"
            v-model="form.otherComment"
            placeholder="Здесь можно указать любую дополнительную информацию"
            isTextArea
          />
        </div>
        <div v-if="!isEventForm" class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="detailInfo"
            label="Ожидаемые результаты реализации проекта и их прогнозные значения"
            v-model="form.projectFeatures"
            placeholder="Опишите, зачем нужен проект? Что он даст аудитории?"
            isTextArea
          />
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="financeSource"
            label="Объемы и источники финансирования"
            v-model="form.financeSource"
            placeholder="Например, государственный контракт"
            isTextArea
          />
        </div>
        <div v-if="!isEventForm" class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="financeSource"
            label="Инструмент реализации"
            v-model="form.implementationTool"
            placeholder="Например, Государственная программа"
            isTextArea
          />
        </div>
        <div v-if="!isEventForm" class="lk-plan-form__field">
          <div class="lk-plan-form__field">
            <UTextInput
              class="u-text-input--smaller"
              name="siteLink"
              label="Ссылка на сайт"
              v-model="form.siteLink"
              placeholder="..."
            />
          </div>
        </div>
        <div class="lk-plan-form__field u-select u-select--small">
          <div class="lk-plan-form__label">
            Цели проведения и их соответствие задачам Десятилетия науки и технологий*
          </div>
          <div class="lk-plan-form__select-wrapper" :class="{ 'u-select--no-interactive': eventObjectivesOptions.length <= 1 }">
            <v-select
              v-model="form.eventObjectives"
              :class="{
                'vs--error': isRequiredErrorField('eventObjectives')
              }"
              :clearable="false"
              :searchable="true"
              label="name"
              :components="{ OpenIndicator }"
              :reduce="option => option.id"
              :options="eventObjectivesOptions"
              placeholder="..."
              multiple
            >
              <template #no-options>
                Не найдено
              </template>
            </v-select>
            <ErrorFormWrapper
              :isVisible="isRequiredErrorField('eventObjectives')"
              class="lk-plan-form__error-field"
            >
              <div>Обязательное поле</div>
            </ErrorFormWrapper>
          </div>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="responsiblePerson"
            label="ФИО ответственного за реализацию"
            v-model="form.responsiblePerson"
            placeholder="…"
          />
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="phone"
            label="Моб. номер ответственного за реализацию"
            v-model="form.responsiblePersonTel"
            placeholder="+7 ("
            mask="+7 (###) ###-##-##"
          />
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="responsiblePersonEmail"
            label="E-mail ответственного за реализацию"
            v-model="form.responsiblePersonEmail"
            placeholder="…"
            :error="$v.form.responsiblePersonEmail.$error"
          />
          <ErrorFormWrapper
            :isVisible="$v.form.responsiblePersonEmail.$error && (!$v.form.responsiblePersonEmail.email || !$v.form.responsiblePersonEmail.isGmail)"
            class="lk-plan-form__error-field"
          >
            <div v-show="$v.form.responsiblePersonEmail.$error && !$v.form.responsiblePersonEmail.email">Неправильный e-mail</div>
            <div v-show="$v.form.responsiblePersonEmail.$error && !$v.form.responsiblePersonEmail.isGmail">Укажите почту в российской зоне .ru</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <div class="lk-plan-form__label">
            Поставьте галочку, если вы считаете {{ isEventForm ? 'мероприятие' : 'проект' }} ключевым событием в рамках Десятилетия науки и технологий в вашем регионе
          </div>
          <CustomCheckbox v-model="form.important">
            Наиболее {{ isEventForm ? 'важное мероприятие' : 'важный проект' }}
          </CustomCheckbox>
        </div>
        <div class="lk-plan-form__field lk-plan-form__field--space-between">
          <div class="lk-plan-form__label lk-plan-form__label--lg-gap">
            Изображение для карточки мероприятия, максимум 5 фото ({{ stringValidFormatFile }}. 2 Мб макс.)
          </div>
          <div class="lk-plan-form__field-el">
            <MediaLoaderController
              ref="mediaController"
              :validFormat="validFormatFile"
              :initialFiles="form.file"
              :maxSize="2"
              :maxCount="5"
              @change="onFileChangeImageEvent"
            />
          </div>
        </div>
      </div>
      <h3 class="lk-plan-form__caption">
        Результаты реализации
      </h3>
      <div
        class="lk-plan-form__fields"
        :class="{ 'lk-plan-form__fields--gap-large': isInvalidForm }"
      >
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="fullTimeParticipants"
            label="Охват очных участников*"
            v-model="form.fullTimeParticipants"
            placeholder="..."
            :error="isRequiredErrorField('fullTimeParticipants') || isDecimalErrorField('fullTimeParticipants')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('fullTimeParticipants') || isDecimalErrorField('fullTimeParticipants')"
            class="lk-plan-form__error-field"
          >
            <div v-show="isRequiredErrorField('fullTimeParticipants')">Обязательное поле</div>
            <div v-show="isDecimalErrorField('fullTimeParticipants')">Возможен ввод только цифр</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="distanceParticipants"
            label="Охват дистанционных участников*"
            v-model="form.distanceParticipants"
            placeholder="..."
            :error="isRequiredErrorField('distanceParticipants') || isDecimalErrorField('distanceParticipants')"
          />
          <ErrorFormWrapper
            :isVisible="isRequiredErrorField('distanceParticipants') || isDecimalErrorField('distanceParticipants')"
            class="lk-plan-form__error-field"
          >
            <div v-show="isRequiredErrorField('distanceParticipants')">Обязательное поле</div>
            <div v-show="isDecimalErrorField('distanceParticipants')">Возможен ввод только цифр</div>
          </ErrorFormWrapper>
        </div>
        <div class="lk-plan-form__field">
          <UTextInput
            class="u-text-input--smaller"
            name="professionalsCount"
            label="Охват профессионального сообщества*"
            v-model="form.professionalsCount"
            placeholder="..."
            :error="isRequiredErrorField('professionalsCount') || isDecimalErrorField('professionalsCount')"
          />
          <ErrorFormWrapper
            class="lk-plan-form__error-field"
            :isVisible="isRequiredErrorField('professionalsCount') || isDecimalErrorField('professionalsCount')"
          >
            <div v-show="isRequiredErrorField('professionalsCount')">Обязательное поле</div>
            <div v-show="isDecimalErrorField('professionalsCount')">Возможен ввод только цифр</div>
          </ErrorFormWrapper>
        </div>
      </div>
    </div>
    <div class="lk-plan-form__footer">
      <ErrorFormWrapper class="lk-plan-form__errors" :isVisible="!isValidForm">
        <div>*Поля обязательны для заполнения </div>
      </ErrorFormWrapper>
      <ErrorFormWrapper
        class="lk-plan-form__feedback-errors"
        :isVisible="Boolean(errorsAfterSubmit?.length)"
      >
        <div
          v-for="(error, i) in errorsAfterSubmit"
          :key="`lk-plan-form__feedback-error-${error.code}-${i}`"
          class="lk-plan-form__feedback-error"
        >
          <span v-html="error.message" />
        </div>
      </ErrorFormWrapper>
      <div class="lk-plan-form__actions">
        <CustomButton
          class="lk-plan-form__btn-submit"
          theme="primary"
          size="md"
          :status="statusSubmitBtn"
          :disabled="disabledSubmitBtn"
          @click="onSubmit"
        >
          Отправить
        </CustomButton>
        <CustomButton
          v-if="isCreate && allowDraftsSaving"
          class="lk-plan-form__btn-submit"
          theme="primary"
          size="md"
          :status="statusDraftBtn"
          :disabled="disabledDraftBtn"
          @click="onSubmitSave"
        >
          Сохранить как черновик
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import { required, decimal, maxLength, email } from "vuelidate/lib/validators";
import { checkGmail } from '@/components/helpers/validate.js'
import {
  mutateClearFilesFromForm,
  mutateOnFilesChange,
  mutateSetIsDeletedFile,
} from '@/components/helpers/formHelpers.js'
import cloneDeep from 'lodash-es/cloneDeep'

import { mapActions, mapState, mapMutations } from 'vuex'

const defaultFieldsForm = {
  initiative: '',
  eventName: '',
  organizer: [],
  coExecutors: [],
  financeSource: '',
  region: '',
  eventLevel: '',
  dateFrom: '',
  dateTo: '',
  eventObjectives: [],
  announcement: '',
  detailInfo: '',
  siteLink: '',
  professionalsCount: '',
  responsiblePerson: '',
  responsiblePersonTel: '',
  responsiblePersonEmail: '',
  file: [],
  otherComment: '',
  meetingFormat: '',
  targetAudience: [],
  important: false
}

const validateDefaultFields = {
  initiative: { required },
  eventName: { required },
  organizer: { required },
  eventLevel: { required },
  region: { required },
  dateFrom: { required },
  dateTo: { required },
  eventObjectives: { required },
  announcement: { required, maxLength: maxLength(210) },
  fullTimeParticipants: { required, decimal },
  detailInfo: { required },
  distanceParticipants: { required, decimal },
  professionalsCount: { required, decimal },
  meetingFormat: { required },
  targetAudience: { required },
  responsiblePersonEmail: { email, isGmail: checkGmail }
}

export default {
  mixins: [validationMixin],
  components: { vSelect },
  props: {
    initiativesOptions: { type: Array, default: () => [] },
    eventLevelOptions: { type: Array, default: () => [] },
    regionOptions: { type: Array, default: () => [] },
    meetingFormatOptions: { type: Array, default: () => [] },
    targetAudienceOptions: { type: Array, default: () => [] },
    eventObjectivesOptions: { type: Array, default: () => [] },
    projectForm: {
      type: Object,
      default: () => ({
        ...defaultFieldsForm,
        implementationTool: '',
        projectFeatures: '',
      })
    },
    eventForm: {
      type: Object,
      default: () => ({
        ...defaultFieldsForm,
        venue: '',
      })
    },
    submitUrl: { type: String, default: '' },
    requestId: { type: [String, Number], default: '' },
    type: { type: String, default: 'event' /* 'event' | 'project' */ },
    validFormatFile: {
      type: Array,
      default: () => [
        'jpg',
        'jpeg',
        'png',
      ]
    },
    reloadAfterSuccessReponse: { type: Boolean, default: false },
    title: { type: String, default: '' },
    isCreate: { type: Boolean, default: false },
    eventDrafts: { type: Array, default: () => [] },
    actionSaveDraft: {
      type: String,
      default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=addEventDraft'
    },
    actionUpdateDraft: {
      type: String,
      default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=updateEventDraft'
    },
    actionGet: {
      type: String,
      default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=getEvent'
    },
    actionUpdate: {
      type: String,
      default: '/bitrix/services/main/ajax.php?mode=class&c=dalee:event_form&action=updateEvent'
    },
    isPartnerForm: {
      type: Boolean,
      default: false
    },
    allowDraftsSaving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValidForm: false,
      form: null,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      minDate: null,
      maxDate: null,
      isInvalidForm: false,
      validationForm: {
        event: { ...validateDefaultFields, venue: { required } },
        project: { ...validateDefaultFields },
      },
      draftModel: null,
    }
  },
  validations() {
    return { form: this.validationForm[this.type] }
  },
  computed: {
    ...mapState(
      'lkEvents',
      ['statusSubmitBtn', 'statusDraftBtn', 'errorsAfterSubmit', 'errorsAfterGetEvent', 'disabledDraftBtn', 'disabledSubmitBtn', 'isLoadingGetEvent']
    ),
    isEventForm() {
      return this.type === 'event'
    },
    getFormData() {
      const data = new FormData()
      data.append('type', this.type);

      for (let prop in this.form) {
        if (['file', 'organizer', 'coExecutors'].includes(prop)) {
          data.append(prop, JSON.stringify(this.form[prop]))
        } else {
          data.append(prop, this.form[prop])
        }
      }

      mutateSetIsDeletedFile(
        ['file'],
        data,
        this.getCurrentFormByType,
        this.form
      )

      return data
    },
    getCurrentFormByType() {
      return this.isEventForm ? this.eventForm : this.projectForm
    },
    getOtherCountry() {
      return this.regionOptions.find(item => item.code === 'otherCountry')
    },
    getAllRegion() {
      return this.regionOptions.find(item => item.code === 'all')
    },
    getOtherRegion() {
      return this.regionOptions.find(item => item.code === 'otherRegion')
    },
    drafts() {
      return this.eventDrafts.filter(item => item.type === this.type)
    },
    draftsOptions() {
      const options = this.drafts.map(item => ({ name: item.name, code: item.id, id: item.id }))
      const voidOption = { name: 'Не выбрано', code: '', id: '' }

      if (this.draftModel) {
        options.unshift(voidOption)
      }

      return options
    },
    stringValidFormatFile() {
      return this.validFormatFile.join(', ')
    }
  },
  methods: {
    ...mapActions('lkEvents', ['SEND', 'SAVE', 'GET_EVENT']),
    ...mapMutations('lkEvents', ['RESET']),
    resetForm() {
      this.initForm()
      this.$nextTick().then(() => {
        this.$refs.mediaController?.reInitFiles()
      })
    },
    scrollToForm(to = 'start') {
      this.$refs.lkPlanForm.scrollIntoView({
        behavior: 'smooth',
        block: to,
      })
    },
    getRegionsWithoutUniqueItems() {
      if (!this.form.region || !Array.isArray(this.form.region)) {
        return this.form.region
      }

      return this.form.region.filter(region => {
        if (
          ![
            this.getOtherCountry?.name,
            this.getOtherRegion?.name,
            this.getAllRegion?.name,
          ].includes(region)
        ) {
          return region
        }
      })
    },
    onChangeRegion(selectedRegions) {
      const regionName = selectedRegions[selectedRegions.length - 1] || null

      if (
        (this.getOtherCountry && regionName === this.getOtherCountry.name)
        || (this.getAllRegion && regionName === this.getAllRegion.name)
        || (this.getOtherRegion && regionName === this.getOtherRegion.name)
      ) {
        this.form.region = [regionName]
      } else {
        this.form.region = this.getRegionsWithoutUniqueItems()
      }
    },
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollToForm()
        this.isInvalidForm = true

        return
      }

      const form = this.getFormData
      const idUpdate = this.requestId || this.draftModel

      if (idUpdate) {
        form.append('idUpdate', idUpdate)
      }

      if (this.isPartnerForm) {
        form.append('byPartner', 1)
      }

      const url = idUpdate ? this.actionUpdate : this.submitUrl

      this.SEND({ url, form, successHandler: this.succesResponse })
    },
    succesResponse(response) {
      this.$v.$reset();
      this.resetForm()

      if (this.requestId && !this.reloadAfterSuccessReponse) {
        this.$root.$refs.customModal.closeModal(null, null, true);
        this.$root.$emit('editedLkPlan', response.data.data.update_id, 'event')
      } else {
        window.location.reload();
      }
    },
    onSubmitSave() {
      this.$v.form.eventName.$touch();

      if (this.$v.form.eventName.$invalid) {
        this.scrollToForm()
        return
      }

      const url = this.draftModel ? this.actionUpdateDraft : this.actionSaveDraft
      const form = this.getFormData

      if (this.draftModel) {
        form.append('idUpdate', this.draftModel)
      }

      if (this.isPartnerForm) {
        form.append('byPartner', 1)
      }

      this.SAVE({ url, form, successHandler: this.onSubmitSaveSuccess })
    },
    onSubmitSaveSuccess() {
      window.location.reload();
    },
    onSelectDraft(id) {
      if (!id) {
        this.draftModel = null
        this.resetForm()
        return
      }

      this.GET_EVENT({
        url: this.actionGet,
        id,
        successHandler: this.onSelectDraftSuccess
      })
    },
    onSelectDraftSuccess(response) {
      const form = this.type === 'event' ? response.data.data.eventForm : response.data.data.projectForm

      this.initForm(form)
      this.$nextTick().then(() => {
        this.$refs.mediaController?.reInitFiles()
      })
    },
    regionSelect(value) {
      if (value.indexOf('all') > -1) {
        if (value.length > 1 && value[0] === 'all') {
          this.form.region = value.splice(1);
        } else {
          this.form.region = ['all'];
        }
      }
    },
    getLengthInfo(field) {
      return `${this.form[field]?.length || 0} / ${this.getMaxLengthValidate(field)}`
    },
    getMaxLengthValidate(field) {
      return this.$v.form[field].$params.maxLength.max
    },
    isRequiredErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].required
    },
    isMaxLengthErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].maxLength
    },
    isDecimalErrorField(field) {
      return this.$v.form[field].$error && !this.$v.form[field].decimal
    },
    clearFiles(form) {
      for (let prop in form) {
        if (prop.startsWith('file') && prop !== 'file') {
          delete form[prop]
        }
      }
    },
    onFileChange(files, fileProp) {
      mutateClearFilesFromForm(this.form, fileProp)
      mutateOnFilesChange(this.form, files, fileProp)
    },
    onFileChangeImageEvent(files) {
      this.onFileChange(files, 'file')
    },
    initForm(fields = {}) {
      const form = { ...cloneDeep(this.getCurrentFormByType), ...fields }

      const minDate = new Date(2022, 0, 1)
      const maxDate = new Date(2031, 11, 31)
      this.minDate = minDate.toLocaleDateString('ru-RU')
      this.maxDate = maxDate.toLocaleDateString('ru-RU')

      if (!form.dateFrom) {
        form.dateFrom = this.minDate
      }
      if (!form.dateTo) {
        form.dateTo = this.maxDate
      }

      this.form = form
    },
    scrollToPlanFormHandler(id) {
      this.scrollToForm()

      if (id) {
        this.onSelectDraft(id)
      }
    }
  },
  created() {
    this.RESET()
    this.initForm()
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$on('scrollToPlanForm', this.scrollToPlanFormHandler)
    })
  },
  beforeDestroy() {
    this.$root.$off('scrollToPlanForm', this.scrollToPlanFormHandler)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.lk-plan-form';

#{$b} {
  position: relative;
  font-family: $font-family-inter;
  color: $black-true;

  // .lk-plan-form--is-loading
  &--is-loading {
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
  }

  // .lk-plan-form__header
  &__header {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .lk-plan-form__title
  &__title {
    font-family: $font-family-fugue;
    font-size: 28px;
    line-height: 1;
    font-weight: 500;
    margin: 0;

    @include mobile {
      font-family: $font-family-inter;
      font-size: 22px;
      line-height: 1.3;
    }
  }

  // .lk-plan-form__fields
  &__fields {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, calc(50% - 16px));
    gap: 32px;
    transition: $transtion-default;

    @include tablet {
      grid-template-columns: repeat(2, calc(50% - 8px));
      gap: 16px;
    }

    @include mobile {
      display: block;
    }

     // .lk-plan-form__fields--gap-large
    &--gap-large {
      gap: 42px 32px;

      @include tablet {
        gap: 42px 16px;
      }
    }
  }

  // .lk-plan-form__field
  &__field {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    // .lk-plan-form__field--space-between
    &--space-between {
      justify-content: space-between;
    }

    &:not(:last-child) {
      @include mobile {
        margin-bottom: 24px;
      }
    }

    // .lk-plan-form__field-el
    &-el {
      margin-left: 8px;
    }

    // .lk-plan-form__field-label
    &-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    // .lk-plan-form__field-length
    &-length {
      transition: $transtion-default;

      // .lk-plan-form__field-length--is-error
      &--is-error {
        color: $color-error;
      }
    }
  }

  // .lk-plan-form__select-wrapper
  &__select-wrapper {
    position: relative;
  }

  // .lk-plan-form__create-options
  &__create-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  // .lk-plan-form__error-field
  &__error-field {
    @include mobile-min {
      position: absolute;
      top: 100%;
    }
  }

  // .lk-plan-form__label
  &__label {
    font-family: $font-family-inter;
    font-size: 12px;
    line-height: 1.3;
    text-transform: initial;
    margin-bottom: 8px;
    margin-left: 8px;
    font-weight: normal;

    &--lg-gap {
      margin-bottom: 17px;

      @include mobile {
        margin-bottom: 24px;
      }
    }
  }

  // .lk-plan-form__caption
  &__caption {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;
    margin: 64px 0 32px 0;

    @include mobile {
      font-size: 16px;
      line-height: 1.3;
      margin: 48px 0;
    }
  }

  // .lk-plan-form__footer
  &__footer {
    margin-top: 32px;

    @include mobile {
      margin-top: 24px;
    }
  }

  // .lk-plan-form__actions
  &__actions {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 64px;

    @include mobile {
      display: block;
      margin-top: 32px;

      & > * {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }

  // .lk-plan-form__feedback-error
  &__feedback-error {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;

    @include mobile {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }

  // .lk-plan-form__no-options
  &__no-options {
    cursor: pointer;
  }

  // .lk-plan-form__draft
  &__draft {
    margin-bottom: 40px;

    // .lk-plan-form__draft-caption
    &-caption {
      margin-bottom: 16px;
    }
  }
}
</style>
