<template>
  <section class="talisman-about">
    <UFigures :items="figures" :full-width="true" position="static" />
    <div class="u-g-container">
      <div class="talisman-about__inner">
        <div v-if="isTitleAbove" class="talisman-about__main">
          <h2 class="talisman-about__main-title title-xl tt-uppercase">
            {{ title }}
          </h2>
        </div>
        <div class="talisman-about__inner-grid">
          <div class="talisman-about__main" :class="{'talisman-about__main--title-above': isTitleAbove}">
            <h2 v-if="!isTitleAbove" class="talisman-about__main-title title-xl tt-uppercase">
              {{ title }}
            </h2>
            <p v-if="subtitle" class="talisman-about__main-subtitle text-lg" v-html="subtitle" />
            <div v-if="description" class="talisman-about__main-text text-ll" v-html="description" />
            <CustomButton
              v-if="btnLink"
              tag="a"
              :href="hrefWithRegion(btnLink)"
              theme="primary"
              size="md"
              class="talisman-about__btn"
              @click="onClick"
            >
              {{ btnText }}
            </CustomButton>
          </div>
          <div v-if="caption || picture" class="talisman-about__other">
            <div v-if="caption" class="talisman-about__caption" v-html="caption" />
            <img v-if="picture" :src="picture" :alt="title" class="talisman-about__other-picture">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  inject: ['ymHandler'],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    btnLink: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    picture: {
      type: String,
      default: ''
    },
    titlePosition: {
      type: String,
      default: 'into', // above || into
    },
    ymBtn: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-talisman-about',
          image: '/frontend/assets/img/bg-shapes/circle-left-opacity.svg',
          animation: 'fade-in-left'
        }
      ]
    }
  },
  computed: {
    isTitleAbove() {
      return this.titlePosition === 'above'
    }
  },
  methods: {
    onClick() {
      if (!this.ymBtn || !Object.values(this.ymBtn).length) {
        return
      }

      this.ymHandler({ code: this.ymBtn.code, type: this.ymBtn.type ,operation: this.ymBtn.operation })
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.talisman-about';

#{$b} {
  position: relative;
  margin-top: 64px;
  margin-bottom: 64px;

  @include mobile {
    margin: 48px 0 72px;
  }

  p:not(class) {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ol:not(class) {
    padding: 0;
    padding-left: 20px;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    li:not(class) {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  // .talisman-about__inner
  &__inner {

    // .talisman-about__inner-grid
    &-grid {
      position: relative;
      display: flex;
      justify-content: space-between;
      z-index: 1;

      @include mobile {
        display: block;
      }
    }
  }

  // .talisman-about__main
  &__main {
    max-width: 800px;
    padding-right: 32px;

    @include mobile {
      padding-right: 0;
    }

    &--title-above {
      padding-top: 32px;

      @include mobile {
        padding-top: 24px;
      }
    }

    // .talisman-about__main-title
    &-title {
      font-family: $font-family-fugue;
      margin: 0;

      #{$b}__inner-grid & {
        margin: 0 0 32px 0;

        @include mobile {
          margin-bottom: 24px;
        }
      }
    }

    // .talisman-about__main-subtitle
    &-subtitle {
      margin: 0 0 16px 0;

      @include mobile {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 24px;
      }
    }

    // .talisman-about__main-text
    &-text {
      max-width: 712px;
      @include mobile {
        font-size: 16px;
      }
    }
  }

  // .talisman-about__other
  &__other {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 398px;

    @include tablet {
      max-width: 298px;
    }
    @include mobile {
      max-width: 100%;
      margin-top: 24px;
    }

    // .talisman-about__other-picture
    &-picture {
      width: 100%;
      max-width: 296px;
      margin-left: auto;
      margin-right: auto;

      &:not(:first-child) {
        margin-top: 24px;
      }

      @include mobile {
        max-width: 284px;
      }
    }
  }

  // .talisman-about__caption
  &__caption {
    padding-top: 26px;
    border-top: 1px solid $black-true;

    & > * {
      &:first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }
    }

  }

  // .talisman-about__btn
  &__btn {
    margin-top: 32px;

    @include mobile {
      width: 100%;
      margin-top: 24px;
    }
  }
}
</style>
