<template>
  <div class="initiatives-gallery-slider">
    <div class="initiatives-gallery-slider__header">
      <h2 class="initiatives-gallery-slider__title title-xl tt-uppercase">
        {{ title }}
      </h2>
      <div class="u-gallery-slider__swiper-controls initiatives-gallery-slider__controls">
        <div class="u-swiper-controls">
          <button
            ref="btnPrev"
            class="u-swiper-controls__btn u-swiper-controls__btn--prev"
          />
          <div
            ref="paginationFraction"
            class="u-swiper-controls__count"
          />
          <button
            ref="btnNext"
            class="u-swiper-controls__btn u-swiper-controls__btn--next"
          />
        </div>
      </div>
    </div>
    <div class="initiatives-gallery-slider__body">
      <swiper
        v-if="isUpdatedSwiper"
        ref="gallerySwiper"
        class="u-gallery-slider__swiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(item,i) in initialItems"
          :key="`u-gallery-slider__swiper-slide-${i}`"
          class="u-gallery-slider__swiper-slide"
        >
          <div
            class="u-gallery-slider__item initiatives-gallery-slider__item"
            :class="{'u-gallery-slider__item--is-video':isVideo(item)}"
            :style="setBackground(item)"
            @click="onOpenLigthBox(i)"
          >
            <div
              v-if="isVideo(item)"
              class="u-gallery-slider__video"
            >
              <div class="u-gallery-slider__video-play">
                <InlineSvg src="play-btn-2" />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="initiatives-gallery-slider__footer" v-if="hrefToAll">
      <CustomButton
        size="md"
        theme="primary"
        tag="a"
        :href="hrefWithRegion(hrefToAll)"
      >
        Все фотографии
      </CustomButton>
    </div>
  </div>
</template>

<script>

import mixinGallerySlider from "@/mixins/mixinGallerySlider";

export default {
  name: "InitiativesGallerySlider",
  mixins: [mixinGallerySlider],
  props: {
    hrefToAll: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Фотогалерея'
    }
  },
  computed: {
    swiperOptions() {
      return {
        slidesPerView: 1.29,
        slidesPerGroup: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: this.$refs.btnPrev,
          nextEl: this.$refs.btnNext,
          disabledClass: "u-swiper-controls__btn--is-disabled"
        },
        pagination: {
          el: this.paginationEl,
          type: "fraction",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          },
          1280: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            pagination: {
              el: this.paginationEl,
              type: "fraction"
            },
          }
        }
      }
    },
  },
  methods: {
    setPaginationEl() {
      this.paginationEl = this.$refs.paginationFraction;
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.initiatives-gallery-slider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;
      margin-bottom: 24px;
    }
  }

  &__title {
    font-family: $font-family-fugue;
    margin: 0 0;
  }

  &__item {
    height: 300px;
  }

  &__footer {
    margin-top: 32px;

    @include tablet {
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }

  &__controls {
    display: block;

    @include tablet {
      display: block;
    }
  }
}
</style>
