<template>
  <components
    :is="cardTag"
    :href="hrefWithRegion(href)"
    class="science-news-card"
  >
    <div class="science-news-card__content">
      <time class="science-news-card__date tt-uppercase">
        {{ getCalendarDate(data.date) }}
      </time>
      <div class="science-news-card__info">
        <h3
          class="science-news-card__title title-lg tt-uppercase"
          v-html="data.title"
        />
        <CustomButton
          v-if="hasDetail"
          class="science-news-card__more"
          theme="outline-light"
        >
          Подробнее
        </CustomButton>
      </div>
    </div>
  </components>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      require: true
    }
  },

  methods: {
    getCalendarDate(date) {
      moment.updateLocale("ru", {
        calendar: {
          lastDay: "[Вчера]",
          sameDay: "[Сегодня]",
          nextDay: "[Завтра]",
          lastWeek: "D MMMM",
          nextWeek: "D MMMM",
          sameElse: "D MMMM"
        }
      });
      return date ? moment(date).calendar() : "";
    },
  },
  computed: {
    hasDetail() {
      return (Boolean(this.data) & this.data.hasDetail)
    },
    cardTag() {
      return this.hasDetail ? 'a' : 'article'
    },
    href() {
      return this.hasDetail ? this.data.url : ''
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.science-news-card {
  position: relative;
  height: 100%;
  min-height: 400px;
  padding: 24px;
  color: $white-true;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: $color-base;
  border-radius: 16px;
  z-index: 0;
  text-decoration: none;

  @include mobile {
    min-height: 230px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &__content {
    max-width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

  }

  &__date {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.1em;
    margin-bottom: 24px;

    &-label {
      display: inline-block;
    }
  }

  // .science-news-item__info
  &__info{
    margin-top: auto;
  }

  &__title {
    color: inherit;
    margin: 0;
    word-break: break-word;
    font-family: $font-family-fugue;
  }

  &__more {
    margin-top: 24px;
  }
}
</style>
