<template>
  <div class="u-events-list">
    <ul class="u-events-list__list">
      <li v-if="isEditable">
        <AddendumCard class="u-events-list__addendum-card" @click="$emit('addNewEvent')">
          Добавить Проекты <br> и мероприятия
        </AddendumCard>
      </li>
      <li
        v-for="(item, i) in items"
        :key="i"
        :class="{
          '_additional': item.isAdditional,
          '_regional': item.isRegional,
        }"
      >
        <component
          :is="isLinkCard ? 'a' : 'div'"
          v-bind="isLinkCard ? { href: hrefWithRegion(item.url) } : {}"
          class="u-events-list__item"
          :class="{'u-events-list__item_not-link' : !isLinkCard}"
        >
          <div v-if="item.image" class="u-events-list__item-media">
            <div
              class="u-events-list__item-image"
              :style="{ backgroundImage: `url('${item.image}')` }"
            />
          </div>
          <div class="u-events-list__item-body">
            <div class="u-events-list__item-subinfo">
              <div class="u-events-list__item-other">
                <div class="u-events-list__item-date">
                  <div class="u-events-list__item-date-label tt-uppercase">
                    <span v-if="item.dateText" v-html="item.dateText" />
                    <div v-else>
                      <span v-for="(item, i) in periodItems(item.dateFrom, item.dateTo, item.onlyMonths)" :key="i">
                        <span v-if="i === 1" class="separator"> — </span>
                        {{ item }}
                      </span>
                    </div>
                  </div>
                  <div class="u-events-list__item-additional" v-if="item.isAdditional">дополнительное мероприятие</div>
                  <div class="u-events-list__item-regional" v-if="item.isRegional && item.id !== 4828">региональное мероприятие</div>
                </div>
                <div v-if="item.type" class="u-events-list__item-type tt-uppercase" v-html="item.type" />
              </div>
              <div class="u-events-list__item-organizers" v-if="item.organizers.length">
                <span>При поддержке: </span>
                <span v-html="item.organizers.map(item => item.name).join(', ')" />
              </div>
            </div>
            <div class="u-events-list__item-info">
              <h3 class="u-events-list__item-title tt-uppercase" v-html="item.title" />
              <p
                v-if="Boolean(item.text)"
                class="u-events-list__item-description"
                v-html="item.text"
              />
              <div class="u-events-list__item-footer">
                <div class="u-events-list__item-place">
                  <span v-html="item.place" />
                </div>
                <CustomButton
                  v-if="!isHiddenBtn && !isEditable"
                  class="u-events-list__item-show-more"
                  tag="a"
                  :href="hrefWithRegion(item.url)"
                  theme="outline-inherit"
                >
                  Узнать больше
                </CustomButton>
              </div>
            </div>
          </div>
          <EditingCard
            v-if="isEditable"
            @edit="$emit('edit', item.id)"
            @remove="$emit('remove', item.id)"
          />
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    items: {
      type: Array,
      require: true
    },
    isHiddenBtn: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isLinks: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isLinkCard() {
      return !this.isHiddenBtn && !this.isEditable
    }
  },
  methods: {
    periodItems(from, to, onlyMonths = false) {
      const dateFrom = moment(from);
      const dateTo = moment(to);

      if (onlyMonths) {
        return this.periodItemsOnlyMonths(dateFrom, dateTo);
      }

      let periods = [];
      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format("DD MMMM"));
      } else {
        if (dateFrom.isSame(dateTo, "year")) {
          if (dateFrom.isSame(dateTo, "month")) {
            periods.push(dateFrom.format("DD"), dateTo.format("DD MMMM"));
          } else {
            periods.push(dateFrom.format("DD MMMM"), dateTo.format("DD MMMM"));
          }
        } else {
          periods.push(dateFrom.format("YYYY"), dateTo.format("YYYY"));
        }
      }

      return periods;
    },
    periodItemsOnlyMonths(dateFrom, dateTo) {
      let periods = [];

      if (dateFrom.isSame(dateTo)) {
        periods.push(dateFrom.format("MMMM"));
      } else {
        if (dateFrom.isSame(dateTo, "year")) {
          if (dateFrom.isSame(dateTo, "month")) {
            periods.push(dateTo.format("MMMM"));
          } else {
            periods.push(dateFrom.format("MMMM"), dateTo.format("MMMM"));
          }
        } else {
          periods.push(dateFrom.format("MMMM YYYY"), dateTo.format("MMMM YYYY"));
        }
      }

      return periods;
    },
  }
}
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events-list';

@mixin additionalFlex {
  @include desktop-min {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #{$b}__item-additional {
      text-align: right;
      max-width: 170px;
      margin-top: 0;
    }
  }
}

#{$b} {

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
    display: flex;
    flex-wrap: wrap;
    @include mobile-min {
      margin-left: -16px;
      margin-bottom: -16px;
    }

    &>li {
      width: calc(100% / 3);

      @include mobile-min {
        padding-left: 16px;
        margin-bottom: 16px;
      }

      @include low-desktop {
        width: 50%;
      }

      @include mobile {
        width: 100%;
        padding-left: 0;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &:nth-child(3n+2) {

        @include low-desktop-min {
          width: calc(100% / 3 * 2);
        }

        @include desktop-min {
          #{$b}__item-regional {
            margin-top: 0;
          }
        }

        &._additional,
        &._regional {
          #{$b}__item-date {
            width: 100%;
            @include additionalFlex;
          }
        }
      }

      &:nth-child(6n+3),
      &:nth-child(6n+4) {

        @include low-desktop-min {
          width: 50%;
        }
      }

      &:nth-child(6n+2),
      &:nth-child(6n),
      &:nth-child(6n+3) {
        #{$b}__item {
          background-color: $color-base;
          color: $white-true;
          border-color: $white-true;
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 0;
    min-height: 400px;
    height: 100%;
    display: flex;
    text-decoration: none;
    background-color: rgba($color: $white-true, $alpha: 0.8);
    transition: .2s ease;
    transition-property: transform,box-shadow;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: $black-true;

    &:hover,
    &:focus {
      @include mobile-min {
        transform: scale(1.01);
        box-shadow: $shadow-default-hover;
      }
    }

    &_not-link {
      cursor: default;
    }

    @include mobile {
      height: auto;
      flex-direction: column;
    }

    &-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 24px;
    }

    &-subinfo{
      margin-bottom: 16px;
    }

    &-info{
      margin-top: auto;
    }

    &-other{
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    &-date {
      margin-right: 10px;
    }

    &-date-label,
    &-type {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: inherit;
      letter-spacing: 0.1em;
    }

    &-additional,
    &-regional {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-top: 20px;
    }

    &-organizers {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;
      color: inherit;
    }

    &-title {
      display: block;
      word-break: break-word;
      font-family: $font-family-fugue;
      font-weight: 500;
      font-size: 28px;
      line-height: 1;
      margin: 0;
    }

    &-description {
      margin: 16px 0 0 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      @include mobile {
        display: block;
        margin-top: 16px;
      }
    }

    &-place {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.3;

      @include mobile {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      @include mobile-min {
        max-width: calc(100% - 160px);
      }
    }

    &-media {
      background-color: #eee;
      flex-shrink: 0;

      @include mobile {
        order: -1;
      }

      #{$b}__list>li:nth-child(6n+1) &,
      #{$b}__list>li:nth-child(6n+6) & {

        @include mobile-min {
          display: none;
        }
      }

      #{$b}__list>li:nth-child(3n+2) & {
        border-radius: inherit;
        overflow: hidden;

        @include low-desktop-min {
          display: block;
          width: 296px;
        }
      }

      #{$b}__list>li:nth-child(6n+3) &,
      #{$b}__list>li:nth-child(6n+4) & {
        @include mobile-min {
          @include full-abs;
          z-index: -1;

          &:before {
            content: "";
            @include full-abs;
          }
        }
      }

      #{$b}__list>li:nth-child(6n+3) & {
        @include mobile-min {
          &:before {
            background-color: rgba($color-base-origin, 0.6)
          }
        }
      }
      #{$b}__list>li:nth-child(6n+4) & {
        @include mobile-min {
          &:before {
            background-color: rgba($white-true, 0.8);
          }
        }
      }

    }

    &-image {
      display: block;
      width: 100%;
      height: 100%;
      @include bg-cover;

      @include mobile {
        padding-top: 56%;
      }
    }
  }

  // .u-events-list__addendum-card
  &__addendum-card {
    min-height: 376px;
  }
}
</style>
