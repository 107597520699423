import axios from "axios"

export default {
  namespaced: true,
  state: {
    isLoading: false,
  },

  getters: {
    IS_LOADING(state) {
      return state.isLoading
    }
  },

  mutations: {
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
  },

  actions: {
    async LOAD_ITEMS({ commit }, { url, params }) {
      const axiosParams = params && typeof params === 'object' ? params : {}

      try {
        commit('SET_LOADING', true)
        const response = await axios(url, { params: axiosParams })

        if ([200,201].includes(response?.status) && response.data) {

          return response.data.data
        }
      } catch(e) {
        throw new Error(`initiativesEvent:LOAD_ITEMS | ${e}`)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
}
