<template>
  <a
    :class="mainClasses"
    :href="formattedCard.target !== '_blank' ? hrefWithRegion(formattedCard.link) : formattedCard.link"
    :target="formattedCard.target"
  >
    <div class="u-playground-card__body">
      <p
        class="u-playground-card__city tt-uppercase"
        v-if="formattedCard.city"
      >
        {{ formattedCard.city }}
      </p>
      <div class="u-playground-card__content">
        <h3
          class="u-playground-card__title title-lg tt-uppercase"
          v-html="formattedCard.title"
        />
        <p
          class="u-playground-card__description"
          v-html="formattedCard.description"
        />
        <CustomButton
          size="sm"
          :no-hover="false"
          theme="outline-light"
        >
          Подробнее
        </CustomButton>
      </div>
    </div>
    <div class="u-playground-card__cover">
      <img
        class="u-playground-card__image"
        :src="formattedCard.image"
        :alt="formattedCard.title"
      >
    </div>
  </a>
</template>

<script>

export default {
  name: 'UPlaygroundCard',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'default',
      validator: (value) => {
        /*
        * default - main card (vertical image)
        * sm - small card (horizontal image)
        * xl - large card (vertical image, size 2 / 3)
        */
        return ['default', 'sm', 'xl'].includes(value);
      }
    },
    ctaBtnText: {
      type: String,
      default: 'Подробнее'
    }
  },
  computed: {
    mainClasses() {
      const sizeClass = this.size !== 'default'
        ? `u-playground-card--reverse u-playground-card--size-${this.size}`
        : null;

      return [
        'u-playground-card',
        sizeClass
      ]
    },
    formattedCard() {
      const {data} = this;

      const title = data.title || 'Площадка';
      const city = data.city || '';
      const description = data.description || '';
      const link = data.href || '#';
      const image = data.image || '';
      const target = data.target || '_self';
      return {
        ...data,
        title,
        city,
        description,
        link,
        image,
        target,
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.u-playground-card {
  $root: #{&};
  background: $color-base;
  color: $white-true;
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 258px;
  min-height: 394px;
  text-decoration: none;
  overflow: hidden;

  @include low-desktop {
    grid-template-columns: auto 42.43%;
    min-height: 294px;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  &__body {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  &__content {
    margin-top: auto;
  }

  &__cover {
    border-radius: 16px;
    height: 100%;
    overflow: hidden;
    position: relative;

    @include mobile {
      aspect-ratio: 2 / 1;
    }
  }

  &__image {
    height: 100%;
    max-width: none;
    max-height: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__city, &__title, &__description {
    margin: 0; // reset default margins
  }

  &__city {
    letter-spacing: .1em;
    font-weight: 500;
    margin-bottom: 48px;
  }

  &__title {
    font-family: $font-family-fugue;
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 24px;
    line-height: 1.3;
  }

  &--reverse {
    grid-template-columns: 296px auto;

    @include low-desktop {
      grid-template-columns: 36.27% auto;
    }

    #{$root}__title {
      display: flex;
      align-items: flex-end;
      min-height: 2em;

      @include tablet {
        min-height: auto;
      }
    }

    #{$root}__cover {
      order: 1;
      position: relative;

      @include mobile {
        height: 200px;
        aspect-ratio: 2 / 1;
      }

      #{$root}__image {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, $black-true 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
        touch-action: none;
      }
    }

    #{$root}__body {
      order: 2;
    }
  }

  &--size-xl {
    grid-column: span 2;

    @include tablet {
      grid-column: auto;
      display: flex;
      flex-direction: column;

      #{$root}__cover {
        height: 200px;
        aspect-ratio: 2 / 1;
      }

      #{$root}__body {
        margin-top: auto;
      }
    }

    #{$root}__title {
      width: 50%;

      @include tablet {
        width: 100%;
      }
    }

    #{$root}__cover {
      &::after {
        opacity: 0;

        @include tablet {
          opacity: 0.5;
        }
      }
    }
  }

  &--size-sm {
    display: flex;
    flex-direction: column;

    #{$root}__cover {
      height: 200px;
      aspect-ratio: 2 / 1;
    }

    #{$root}__body {
      margin-top: auto;
    }
  }
}
</style>
