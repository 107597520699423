<template>
  <div class="custom-video-block" v-if="isExistsVideoBlock">
    <div class="u-g-container">
      <div class="custom-video-block__header" v-if="title">
        <component
          :is="getTitleTag"
          class="custom-video-block__title tt-uppercase"
          :class="{'custom-video-block__title--is-link': titleLink}"
          :href="hrefWithRegion(titleLink)"
        >
          <span v-html="title" />
        </component>
      </div>
      <div class="custom-video-block__body">
        <CustomVideoCards :videoList="videoList" :isLargeLastCardInTablet="tableLarge" />
      </div>
      <div class="custom-video-block__footer">
        <CustomButton
          class="custom-video-block__footer-btn"
          theme="primary"
          size="md"
          tag="a"
          :href="hrefWithRegion(href)"
        >
          {{ btnText }}
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableLarge: {
      type: Boolean,
      default: true
    },
    videoList: {
      type: Array,
      default: () => []
    },
    btnText: {
      type: String,
      default: 'Все материалы'
    },
    href: {
      type: String,
      default: '/video/'
    },
    title: {
      type: String,
      default: ''
    },
    titleLink: {
      type: String,
      default: ''
    },
  },
  computed: {
    isExistsVideoBlock() {
      return this.videoList && this.videoList.length > 0
    },
    getTitleTag() {
      return this.titleLink ? 'a' : 'h2'
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.custom-video-block';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  // .custom-video-block__header
  &__header {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .custom-video-block__title
  &__title {
    color: $black-true;
    font-family: $font-family-fugue;
    font-size: 64px;
    line-height: 1;
    font-weight: 500;
    margin: 0;

    @include tablet {
      font-size: 48px;
    }

    &--is-link {
      cursor: pointer;
      text-decoration: none;
      transition: $transtion-default;

      &:hover {
        color: $color-base-origin;
      }
    }
  }

  // .custom-video-block__body
  &__body {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .custom-video-block__footer
  &__footer {
    @include mobile {
      text-align: center;
    }
  }
}
</style>
