<template>
  <component
    :is="elTag"
    class="science-playground-card"
    :class="[
      {
        'science-playground-card--is-video': isVideoCard,
        'science-playground-card--is-default': isDefaultCard,
        'science-playground-card--is-gallery': isGalleryCard,
        'science-playground-card--is-info': withInfoBlock,
        'science-playground-card--without-img': withoutImg,
        'science-playground-card--has-shadow': card.isShadow,
      },
      `science-playground-card--type-${type}`,
    ]"

    :href="hrefWithRegion(card.url)"
    @click="openModalHandler"
  >
    <div class="science-playground-card__inner">
      <div v-if="!withoutImg" class="science-playground-card__preview">
        <img :src="card.preview" class="science-playground-card__preview-img">
        <div class="science-playground-card__btn-play">
          <inline-svg class="science-playground-card__icon-play" src="play-icon"></inline-svg>
        </div>
      </div>
      <div v-if="card.title || (card.text && isDefaultCard) || (date)" class="science-playground-card__body">
        <time v-if="date" class="science-playground-card__date">{{ date }}</time>
        <h3 v-if="card.title" class="science-playground-card__title title-lg tt-uppercase" v-html="card.title" />
        <p v-if="card.text && isDefaultCard" class="science-playground-card__text" v-html="card.text" />
        <CustomButton
          v-if="canDetailEl"
          class="science-playground-card__btn"
          :theme="withoutImg ? 'outline-purple' : 'outline-light-purple'"
          @click.stop="onClickBtn"
        >
          Узнать больше
        </CustomButton>
      </div>
    </div>
  </component>
</template>

<script>
import { getFormattedArticleDate } from '@/components/helpers/formatter-date.js'

const TYPE_ENUM = {
  gallery: ['comics', 'aq'],
  video: ['video', 'unboxing', 'aq'],
}

export default {
  inject: ['ymHandler'],
  props: {
    card: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'column'
    },
    enabledYm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVideoCard() {
      return this.canVideo && this.isExistVideoData
    },
    isDefaultCard() {
      return this.typeContent === 'info'
    },
    withoutImg() {
      return this.isDefaultCard && !this.card.preview
    },
    isGalleryCard() {
      return this.canGallery && this.isExistsGalleryData && !this.isVideoCard
    },
    withInfoBlock() {
      return !this.isGalleryCard && !this.withoutImg && (this.card.title || this.card.text)
    },
    elTag() {
      return this.canDetailEl ? 'a' : 'div'
    },
    typeContent() {
      let type = 'info'

      for (const key in TYPE_ENUM) {
        const values = TYPE_ENUM[key]

        if (values.includes(this.card.type)) {
          type = key
        }
      }

      return type
    },
    isExistVideoData() {
      return Object.values(this.card.video).filter(item => !!item).length > 0
    },
    isExistsGalleryData() {
      return this.card.gallery && this.card.gallery.length > 0
    },
    canVideo() {
      return TYPE_ENUM.video.includes(this.card.type)
    },
    canGallery() {
      return TYPE_ENUM.gallery.includes(this.card.type)
    },
    date() {
      return this.card.date ? getFormattedArticleDate(this.card.date) : ''
    },
    canDetailEl() {
      return Boolean((this.isDefaultCard || this.card.type === 'video') && this.card.url)
    },
  },
  methods: {
    openModal(data) {
      this.$root.$emit('playground-modal-open', data)
    },
    openModalHandler() {
      if (this.canDetailEl) {
        return
      }

      if (this.isVideoCard) {
        const options = {
          componentData: this.getModalComponentData('video'),
        }

        if (this.isExistsGalleryData) {
          options.modalSettings = {
            changeModalOptions: {
              video: this.getModalComponentData('video'),
              gallery: this.getModalComponentData('gallery'),
            },
            type: 'video',
          }
        }

        this.openModal(options)
      }

      if (this.isGalleryCard) {
        const options = {
          componentData: this.getModalComponentData('gallery'),
        }

        if (this.isExistVideoData) {
          options.modalSettings = {
            changeModalOptions: {
              video:  this.getModalComponentData('video'),
              gallery: this.getModalComponentData('gallery')
            },
            type: 'gallery',
          }
        }

        this.openModal(options)
      }
    },
    getModalComponentData(type) {
      const data = {
        name: `science-playground-${type}`,
        props: {}
      }

      data.props[type] = this.card[type]
      return data
    },
    onClickBtn() {
      if (!this.enabledYm) {
        return
      }

      this.ymHandler({
        code: window.YM_KEY,
        operation: 'click_learn'
      })
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';


$b: '.science-playground-card';

#{$b} {
  display: block;
  text-decoration: none;
  cursor: pointer;

  &--type-grid {
    height: 100%;
  }

  @include mobile-min {
    &:hover {
      #{$b}__inner {
        box-shadow: 0px 0px 20px 2px rgba($color-purple, 0.75);
      }
    }
  }

  // .science-playground-card--is-video
  &--is-video {

    @include mobile-min {
      &:hover {
        #{$b}__btn-play {
          background-color: $color-purple;
          color: $white-true;
        }
      }
    }
  }

  // .science-playground-card__inner
  &__inner {
    color: $white-true;
    border-radius: 48px;
    overflow: hidden;
    transition: $transtion-default;

    #{$b}--without-img & {
      border: 5px solid $color-purple;
      background-color: $color-secondary-5;
      color: $color-purple;
      padding: calc(32px - 5px);
    }

    #{$b}--is-gallery & {
      position: relative;
    }

    #{$b}--type-grid & {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include mobile {
        display: block;
      }
    }
  }

  // .science-playground-card__preview
  &__preview {

    #{$b}--is-video & {
      position: relative;
      overflow: hidden;
    }

    #{$b}--has-shadow & {
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba($black-true, 0.00) 0%, rgba($black-true, 0.80) 100%);
        pointer-events: none;
        touch-action: none;
      }
    }

    #{$b}--type-grid & {
      height: 100%;
    }

    #{$b}--type-grid#{$b}--is-info & {
      width: 100%;
      height: 200px;
      min-height: 200px;

      @include mobile {
        aspect-ratio: 400/250;
        min-height: auto;
        height: auto;
      }
    }

    // .science-playground-card__preview-img
    &-img {
      width: 100%;
      height: auto;
      margin: 0;
      display: block;

      #{$b}--type-grid & {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  // .science-playground-card__body
  &__body {

    #{$b}--is-info & {
      color: $white-true;
      background-color: $color-purple;
      padding: 24px;

      @include mobile {
        padding: 24px 12px 24px 12px;
      }
    }

    #{$b}--is-gallery & {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 24px 24px 40px 24px;

      @include mobile {
        padding: 24px 12px 32px 12px;
      }
    }

    #{$b}--type-grid & {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include mobile {
        height: auto;
      }
    }
  }

  // .science-playground-card__btn-play
  &__btn-play {
    #{$b}:not(#{$b}--is-video) & {
      display: none !important;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: $white-true;
    color: $color-purple;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: $transtion-default;
  }

  // .science-playground-card__icon-play
  &__icon-play {
    width: 28px;
    height: 28px;
    margin-left: 4px;
  }

  // .science-playground-card__date
  &__date {
    display: inline-block;
    margin-bottom: 12px;

    #{$b}--is-gallery & {
      margin-bottom: auto;
      display: inline-flex;
      align-items: center;
      padding: 5px 10px;
      background: $color-purple;
      border-radius: 12px;
      justify-self: flex-start;
      margin-right: auto;
    }
  }

  // .science-playground-card__title
  &__title {
    font-family: $font-family-fugue;
    margin: 0;

    #{$b}--type-grid & {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  // .science-playground-card__text
  &__text {
    margin: 16px 0 0 0;

    #{$b}--type-grid & {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  // .science-playground-card__btn
  &__btn {
    align-self: flex-start;
    margin-top: 24px;

    #{$b}--type-grid & {
      margin-top: auto;
    }
  }
}

</style>
