<template>
  <div class="u-news-page" :class="{'u-news-page--is-empty': !isExistsPage}">
    <UFigures
      :items="[
        {'name':'f-news-page-1','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-news-page-2','image':'/frontend/assets/img/bg-shapes/circle-group-2-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-news-page-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-news-page-lines','image':'/frontend/assets/img/news/bg-lines.png','animation':'fade-in-left'},
      ]"
      full-width
      position="static"
    >
      <div class="u-news-page__inner">
        <div class="u-news-page__wrapper">
          <div class="u-news-page__filter">
            <div v-if="themesList.length">
              <div class="u-news-page__filter-title title-xl tt-uppercase">Популярные темы</div>
              <FilterTags v-model="selectedThemes" :list="getThemesList" class="u-news-filter__tags" />
            </div>
            <div class="u-news-filter__bottom" v-if="!hideFilter">
              <div class="u-news-filter__tabs" v-if="timePeriod">
                <ul class="u-news-filter__tab-list">
                  <li
                    v-for="item in timePeriod"
                    class="u-news-filter__tab"
                    :key="item.id"
                    :class="{'u-news-filter__tab--active': item.id == currentTimePeriod }"
                    @click="setTimePeriod(item)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <div class="u-news-filter__select-group">
                <div class="u-news-filter__select-date u-select text-md tt-uppercase" v-if="dateOptions.length">
                  <v-select
                    v-model="selectedDate"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    :components="{ OpenIndicator }"
                    :reduce="option => option.id"
                    :options="dateOptions"
                  >
                    <template #no-options>
                      Не найдено
                    </template>
                  </v-select>
                </div>
                <div class="u-news-filter__select-activity u-select text-md tt-uppercase" v-if="activityOptions.length">
                  <v-select
                    v-model="selectedActivity"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    :components="{ OpenIndicator }"
                    :reduce="option => option.id"
                    :options="activityOptions"
                  >
                    <template #no-options>
                      Не найдено
                    </template>
                  </v-select>
                </div>
                <div
                  v-if="initiativeOptions.length && showInitiativeSelect"
                  class="u-news-filter__select-activity u-select text-md tt-uppercase"
                >
                  <v-select
                    v-model="selectedInitiative"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    :components="{ OpenIndicator }"
                    :reduce="option => option.id"
                    :options="initiativeOptions"
                  >
                    <template #no-options>
                      Не найдено
                    </template>
                  </v-select>
                </div>
                <div class="u-news-filter__select-region u-select text-md tt-uppercase" v-if="regionOptions.length">
                  <v-select
                    v-model="regionFilter"
                    :clearable="false"
                    :searchable="true"
                    label="name"
                    :components="{ OpenIndicator }"
                    :reduce="option => option.code"
                    :options="regionOptions"
                  >
                    <template #no-options>
                      Не найдено
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="u-news-page__body">
            <UNewsList
              v-if="isExistsPage"
              :newsList="newsItems"
              class="u-news-page__list"
              ref="newsList"
            />
            <NotFound v-else text="По данным параметрам ничего не найдено" />
            <div
              v-show="paginator.NavPageNomer < paginator.NavPageCount"
              class="u-news-page__action"
            >
              <CustomButton
                class="u-news-page__btn-more"
                size="md"
                theme="primary"
                @click="showNewCards(false)"
              >
                Показать ещё
              </CustomButton>
            </div>
          </div>
        </div>
        <ScrollTopBtn />
      </div>
    </UFigures>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import ScrollTopBtn from "@/components/ScrollTopBtn.vue";
import { mapGetters } from "vuex";
import { regionFromOptions } from '@/components/helpers/regionHelper.js'

export default {
  name: "UNewsPage",
  components: {
    ScrollTopBtn,
    vSelect,
  },
  props: {
    url: {
      type: String,
      required: true
    },
    regionOptions: {
      type: Array,
      default: () => []
    },
    activityOptions: {
      type: Array,
      default: () => []
    },
    initiativeOptions: {
      type: Array,
      default: () => []
    },
    initiativeEnumId: {
      type: Number,
      default: 0
    },
    initiativeCurrent: {
      type: Number,
      default: 0
    },
    dateOptions: {
      type: Array,
      default: () => []
    },
    timePeriod: {
      type: Array,
      default: () => [
        { id: '0', name: 'Все' },
        { id: '1', name: 'Сегодня' }
      ]
    },
    themesList: {
      type: Array,
      default: () => []
    },
    initialNewsItems: {
      type: Array,
      default: () => [
        {
          id: '0',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#',
          hideDate: false,
        },
        {
          id: '1',
          date: '21 марта',
          title: 'Первую ярославскую гибридную электростанцию отправят на Крайний Север',
          text: 'В Ярославской области разработаны электростанции, способные работать от дизельного топлива, энергии ветра и солнца',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#',
          hideDate: false,
        },
        {
          id: '2',
          date: '20 марта',
          title: 'Ученые АлтГУ разработали пленку с антимикробными свойствами для восстановления кожи',
          text: 'Ученые лаборатории клеточных технологий и экспериментальной фармакологии при поддержке внутриуниверситетского гранта завершили один из этапов проекта по регенеративной медицине',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#',
          hideDate: false,
        },
        {
          id: '3',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#',
          hideDate: false,
        },
        {
          id: '4',
          date: '22 марта',
          title: 'Начался прием заявок на&nbsp;конкурс &laquo;Моя страна&nbsp;&mdash; моя Россия&raquo;',
          text: 'Алексей Комиссаров сообщил, что всероссийский конкурс пройдет по&nbsp;17&nbsp;номинациям',
          image: '/frontend/assets/img/news-01.jpg',
          url: '#',
          hideDate: false,
        }
      ]
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      selectedRegion: '',
      selectedActivity: '',
      selectedInitiative: '',
      selectedDate: '',
      showInitiativeSelect: false,
      currentTimePeriod: '0',
      selectedThemes: [],
      newsItems: this.initialNewsItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
      regionHasBeenChanged: false
    }
  },
  watch: {
    selectedThemes() {
      this.loadFirstPage();
    },
    regionFilter() {
      this.loadFirstPage();
    },
    selectedActivity() {
      this.showInitiativeSelect = this.initiativeEnumId === this.selectedActivity;
      if (!this.showInitiativeSelect) {
        this.selectedInitiative = "";
      }

      this.loadFirstPage();
    },
    selectedInitiative() {
      this.loadFirstPage();
    },
    selectedDate() {
      this.loadFirstPage();
    },
    currentTimePeriod() {
      this.loadFirstPage();
    }
  },
  computed: {
    ...mapGetters('region', ['GET_REGION']),
    initialRegion() {
      return regionFromOptions(this.GET_REGION, this.regionOptions)?.code || ''
    },
    regionFilter: {
      get() {
        return this.regionHasBeenChanged ? this.selectedRegion : this.initialRegion
      },
      set(value) {
        this.selectedRegion = value
        this.regionHasBeenChanged = true
      }
    },
    isExistsPage() {
      return Boolean(this.newsItems.length)
    },
    getThemesList() {
      return this.themesList.map(item => {
        const isActive = this.selectedThemes.includes(item.id)

        return {
          ...item,
          isActive: item.isActive || isActive,
        }
      })
    }
  },
  methods: {
    setTimePeriod(value) {
      this.currentTimePeriod = value.id;
    },
    loadFirstPage() {
      const lines = [];
      if (this.selectedThemes.length) {
        this.selectedThemes.map(id => {
          lines.push(`sid[]=${id}`);
        });
      }
      if (this.regionFilter) {
        lines.push(`region=${this.regionFilter}`);
      }
      if (this.selectedActivity) {
        lines.push(`activity=${this.selectedActivity}`);
      }
      if (this.selectedInitiative) {
        lines.push(`initiative=${this.selectedInitiative}`);
      }
      if (this.selectedDate) {
        lines.push(`year=${this.selectedDate}`);
      }
      if (this.currentTimePeriod) {
        lines.push(`period=${this.currentTimePeriod}`);
      }
      const params = lines.join("&");
      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async showNewCards(clearItems){
      const bottom = this.$refs.newsList.$el.getBoundingClientRect().bottom

      await this.loadMoreItems(clearItems)

      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.newsItems = [];
      }

      res.data.ITEMS.map(item => {
        this.newsItems.push(item);
      });
    },
  },
  created() {
    this.loadFirstPage()
  },
  mounted() {
    if (this.initiativeCurrent) {
      this.selectedActivity = this.initiativeEnumId;
      this.selectedInitiative = this.initiativeCurrent;
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

.u-news-page {
  min-height: 600px;
  font-family: $font-family-inter;

  // .u-news-page--is-empty
  &--is-empty {
    .f-news-page-2,
    .f-news-page-3,
    .f-news-page-lines {
      display: none !important;
    }
  }

  // .u-news-page__wrapper
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  // .u-news-page__banner
  &__banner {
    min-height: auto;
  }

  // .u-news-page__filter
  &__filter {
    position: relative;
    padding-top: 64px;
    margin-bottom: 32px;

    @include mobile {
      padding-top: 48px;
      margin-bottom: 48px;
    }
  }

  // .u-news-page__filter-title
  &__filter-title {
    font-family: $font-family-fugue;
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  // .u-news-page__body
  &__body {
    position: relative;
    padding-bottom: 128px;

    @include mobile {
      padding-bottom: 64px;
    }
  }

  // .u-news-page__action
  &__action {
    margin-top: 32px;

    @include mobile {
      margin-top: 24px;

      text-align: center;
    }
  }
}

.u-news-filter {
  // .u-news-filter__tags
  &__tags {
    margin-bottom: 64px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .u-news-filter__bottom
  &__bottom {
    display: flex;
    justify-content: space-between;

    @include tablet {
      // TODO: в билд css медиазапрос выводится выше базового стиля для этого класса
      display: block !important;
    }
  }

  // .u-news-filter__tab-list
  &__tab-list {
    height: 40px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    border: 1px solid $color-base;
    border-radius: 20px;
    overflow: hidden;
  }

  // .u-news-filter__tab
  &__tab {
    height: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color:$color-base;
    padding: 4px 20px;
    user-select: none;
    cursor: pointer;
    transition: $transtion-default;

    &:hover {
      @include mobile-min {
        &:not(.news-filter__tab--active) {
          background-color: rgba($color-base, 0.1);
        }
      }
    }

    // .u-news-filter__tab--active
    &--active {
      background-color: $color-base;
      color:$white-true;
    }
  }

  // .u-news-filter__select-group
  &__select-group {
    display: flex;

    @include tablet {
      margin-top: 48px;
      justify-content: space-between;
    }
    @include mobile {
      display: block;
    }
  }

  // .u-news-filter__select-date
  &__select-date {
    width: 192px;
    margin-right: 16px;

    @include low-desktop {
      width: 150px;
    }

    @include tablet {
      width: calc((100% / 3) - 10px);
      margin: 0;
    }

    @include mobile {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  // .u-news-filter__select-activity
  &__select-activity {
    width: 296px;
    margin-right: 16px;

    @include low-desktop {
      width: 240px;
    }
    @include tablet {
      width: calc((100% / 3) - 10px);
      margin: 0;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 32px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // .u-news-filter__select-region
  &__select-region {
    width: 400px;

    @include low-desktop {
      width: 240px;
    }
    @include tablet {
      width: calc((100% / 3) - 10px);
    }
    @include mobile {
      width: 100%;
    }
  }
}

</style>
